import {useState, useEffect, useCallback} from "react";
import ApiService from "../../services/ApiService";
import descriptions from "../adjectivesDescriptions";
import shuffle from "../shuffle";
import UserSessionStore from "../../services/userSessionStore";
import SlidesStep from "../SlidesStep";
import Slide from "../Slide";
import SlideBox, { SlideBackground } from "../SlideBox";
import Spinner from "../../ui-components/Spinner";

const firstSlide = () => ({
  tutorial: true,
  logos: true,
  start: true,
});

const thirdSlide = (isTutorial) => ({
  title: "Tutorial",
  description:
    isTutorial ?
    "Ora proveremo la dinamica di gioco che è molto simile a un reaction game: per ogni immagine dobbiamo scegliere istintivamente se l'aggettivo indicato è, giusto o sbagliato. È importante rispondere nel minor tempo possibile!" :
    "Ora proverai la dinamica di gioco che è molto simile a un reaction game: per ogni immagine visualizzata dovrai scegliere istintivamente se l'aggettivo indicato è, per te, giusto o sbagliato. È importante rispondere nel minor tempo possibile, quindi tieni i pollici pronti sui bottoni e non pensare troppo!",
  tutorial: true,
});

const middleSlide = (isTutorial) => ({
  title: "Iniziamo",
  description:
    isTutorial ? 
    "Ora che abbiamo provato la dinamica possiamo iniziare: ci saranno 5 round e per ognuno esprimiamo un giudizio di istinto su 24 volti. Rimani concentrat*, per completare i round saranno necessari circa 5 min."
    :
    "Ora che hai provato la dinamica possiamo iniziare: ci saranno 5 round e per ognuno dovrai esprimere il tuo istinto su 24 volti. Rimani concentrat*, per completare i round saranno necessari circa 5 min.",
  tutorial: true,
});

const lastSlide = () => ({
  loader: true,
  tutorial: true,
});


function Slides({sessionId, onComplete, isTutorial = false, isPublicTest = false}) {
  const userSessionstore = new UserSessionStore();
  const [slides, setSlides] = useState(null);
  const [roundsNumber, setRoundsNumber] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const [nextIndex, setNextIndex] = useState(null);
  const [images, setImages] = useState(null);
  const [result, setResult] = useState([]);
  const [userProfilingData, setUserProfilingData] = useState();       // PublicUserProfilingData
  const [firstSlideRerender, setFirstSlideRerender] = useState(isPublicTest);   // trick to avoid first slide rerender on public test
  
  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const [adjectives, images] = await Promise.all([
          ApiService.getApi("adjectives", isPublicTest ? "?public=true" : "?private=true"),
          ApiService.getApi("images"),
        ]);

        if (!adjectives.length || !images.length) {
          throw new Error("missing data");
        }

        setImages(images);

        const tutorialAdjectives = adjectives.filter((a) => a.tutorial);
        const surveyAdjectives = adjectives.filter((a) => !a.tutorial).sort((a, b) => a.id - b.id);

        const generatedSlides = [
          ... isPublicTest ? [{ slideType: "StartGame" }] : [firstSlide()],
          thirdSlide(isTutorial),
          ...tutorialAdjectives
            .filter((s, i) => i === 0)
            .map((a, i) => [
              {
                start: true,
                title: `Proviamo insieme`,
                subtitle: "ROUND PROVA",
                description: a.adjective,
                tutorial: true,
                adjectiveDescription: descriptions[a.adjective.slice(0, -1)],
              },
              {
                id_adjective: a.id,
                adjective: a.adjective,
                tutorial: true,
                images: shuffle(images.filter((img) => img.tutorial)),
              },
            ])
            .reduce((acc, a) => [...acc, ...a], []),
          ... !isTutorial ? [middleSlide(isTutorial)] : [],
          ... isTutorial ? 
            surveyAdjectives
              .filter((s, i) => i === 0)
              .map(() => [
                {
                  end: true,
                  title: "Tutto chiaro?",
                  description: "Perfetto, ora puoi iniziare il test!",
                }
              ])
              .reduce((acc, a) => [...acc, ...a], [])
             : 
            surveyAdjectives
              .map((a, i) => [
                {
                  start: true,
                  title: `Round ${i + 1}`,
                  description: a.adjective,
                  adjectiveDescription: descriptions[a.adjective.slice(0, -1)],
                },
                {
                  id_adjective: a.id,
                  adjective: a.adjective,
                  images: shuffle(images.filter((img) => !img.tutorial)),
                },
              ])
              .reduce((acc, a) => [...acc, ...a], []),
              ... isPublicTest ? [{ slideType: "UserProfiling" }] : [],
              ... isPublicTest ? [{ slideType: "PublicMidGame" }] : [],
              ... isPublicTest ? [{ slideType: "ResultsPrelude" }] : [],
          ... !isTutorial ? [lastSlide()] : [null],
        ].map((s, index) => ({...s, id: index + 1}));

        setSlides(generatedSlides);
        setRoundsNumber(surveyAdjectives.length);
      } catch (error) {
        console.log(error);
        setSlides([]);
      }
    };

    fetchSlides();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const nextSlide = useCallback(
    async (data, save, nextImage) => {
      if (save) {
        setSubmitting(true);
        try {
          const user_id = userSessionstore.getUserData(sessionId)['uid_user'];
          const user_code = userSessionstore.getUserData(sessionId)['cod_user'];
          await ApiService.postApiJson(
            "responses",
            {
              'responses': result.map((r) => ({...r, uid_user: user_id})),
              'public_user_profiling_data': isPublicTest ? {...userProfilingData, 'uid_user': user_id} : undefined
            }
          );

          userSessionstore.setCompleted(sessionId, true);
          onComplete(user_id, user_code, result, slides, images)
        } catch (error) {
          console.log(error);
          setSlides([]);
        }
        return;
      }
      if (data) {
        setResult([...result, data]);
      }
      if (nextImage) {
        return;
      }
      setNextIndex(activeSlide + 1);
      if (activeSlide > 2) {        // enable custom slide render after the first two
        setFirstSlideRerender(true);
      }

      setTimeout(() => {
        setNextIndex(null);
        setActiveSlide(activeSlide + 1);
      }, 700);
    }, [activeSlide, result]);  // eslint-disable-line react-hooks/exhaustive-deps


  if (!slides || submitting) {
    return (
      <SlideBackground>
        <SlideBox>
          <Spinner/>
        </SlideBox>
      </SlideBackground>
    );
  }

  const slide = slides.find((s, index) => index + 1 === activeSlide);
  const showNextSlide = nextIndex
    ? slides.find((s, index) => index + 1 === nextIndex)
    : null;

  return (
    <>
      <>
        <SlidesStep slides={slides} activeSlide={activeSlide} isTutorial={isTutorial}/>

        <Slide
          entry={activeSlide === 1}
          last={slide.id === slides.length}
          nextSlide={nextSlide}
          nextIndex={nextIndex}
          activeSlide={activeSlide}
          index={slide.id}
          key={slide.id}
          isTutorial={isTutorial}
          slideType={slide.slideType}
          userProfilingData={userProfilingData}
          setUserProfilingData={setUserProfilingData}
          firstSlideRerender={firstSlideRerender}
          setFirstSlideRerender={setFirstSlideRerender}
          images={
            slide.images
              ? slide.images.filter((img) =>
                slide.tutorial && slide.id !== slides.length
                  ? img.tutorial
                  : !img.tutorial
              )
              : []
          }
          sessionId={sessionId}
          roundsNumber={roundsNumber}
          {...slide}
        />

        {showNextSlide && (
          <Slide
            entry
            last={showNextSlide.id === slides.length}
            nextSlide={nextSlide}
            nextIndex={nextIndex}
            activeSlide={activeSlide}
            index={showNextSlide.id}
            key={showNextSlide.id}
            isTutorial={isTutorial}
            slideType={slide.slideType}
            firstSlideRerender={firstSlideRerender}
            setFirstSlideRerender={setFirstSlideRerender}
            images={
              slide.images
                ? slide.images.filter((img) =>
                  slide.tutorial && showNextSlide.id !== slides.length
                    ? img.tutorial
                    : !img.tutorial
                )
                : []
            }
            sessionId={sessionId}
            roundsNumber={roundsNumber}
            {...showNextSlide}
          />
        )}
      </>
    </>
  )
}

export default Slides;