import en_faq from "./faq/en_faq";
import en_form from "./form/en";

const en = {
  entity_user: "Management of users in the system",
  entity_role: "Management of authorization roles in the system",
  entity_audit: "Management of audit in the system",
  name_and_surname: "Name and surname",
  language_it: "Italiano",
  language_en: "English",
  language: "Language",
  Home: "Home",
  disabled_user: "Disabled user",
  enter_name_and_surname: "Enter your name and surname",
  enter_name_or_email: "Enter email and name",
  need_to_select_a_role: "You must select a role",
  delete_user: "Delete the user?",
  disabled: "Disabled",
  user_updated_successfully: "User updated successfully",
  user_deleted_successfully: "User successfully deleted",
  user_created_successfully: "User successfully created",
  description: "Description",
  role_name: "Role name",
  add_role_name: "Enter the name of the role",
  add_role: "Add role",
  add_user: "Add user",
  deleted_role: "Role deleted",
  updated: "Updated",
  delete_element: "Are you sure you want to delete the element?",
  role_already_used: "Role already in use!",
  role_created_successfully: "Role created successfully",
  add: "Add",
  server_error: "Server Error",
  confirm_password: "Confirm password",
  passwords_not_matching: "The two passwords do not match",
  password_without_spaces: "The password must not contain spaces",
  password_length: "The password must be at least 8 characters long",
  signup: "Register",
  error_while_saving: "Error while saving",
  authentication_failed: "Authentication failed",
  send: "Send",
  user_not_found: "User not found",
  wrong_old_password: "Wrong old password",
  enter_actual_password: "Enter current password",
  email: "Email",
  name: "Name",
  role: "Role",
  user_type: "User type",
  my_profile: "My profile",
  access_denied: "Access denied",
  password_updated_successfully: "Password changed successfully",
  submit_password: "Send password",
  enter_your_email_address: "Enter the email address associated with your user",
  restore_password: "Recover password",
  forgot_password: "Forgot your password?",
  edit_password: "Change password",
  show_password: "Show password",
  form_error: "Check the form for errors",
  enter_email: "Enter email",
  missing_sender_mail: "Missing sender mail",
  log_into_your_account: "Log into your account",
  wrong_email_or_password: "Wrong email or password",
  unregistered_email: "Email not registered",
  forgot_password_msg: "Mail sent! If you don't hear from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder.",
  roles: "Roles",
  roles_displayed_rows: "{from}-{to} of {count} roles",
  users: "Users",
  users_displayed_rows: "{from}-{to} of {count} users",
  components: "Components",
  maps: "Maps",
  tables: "Tables",
  graphs: "Graphs",
  users_settings: "User management",
  welcome: "Welcome",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Select one or more files",
  success_ticket_sent: "The ticket was added successfully, we will take the request in charge as soon as possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Size limit exceeded",
  customer_portal_loading_failed: "Customer portal link loading error",
  week_datepicker: "Week ",
  week_datepicker_not_selected: "Weekly Datepicker",
  daily_datepicker_not_selected: "Daily Datepicker",
  sessions: "Sessions Management",
  entity_session: "Management of sessions",
  page_not_found: "Page not found",
  go_back_home: "Go back home",
  page_not_found_message: "Sorry, we haven't found what you're searching for",
  gamesession_start: "Start test",
  session_started_at: "Started",
  session_created_at: "Created",
  session_stopped_at: "Stopped",
  copy_id: "Click to copy your id",
  copy_test_link: "Copy test link",
  stop_session: "Stop test",
  session_stopped: "Test stopped correctly",
  session_started: "Test started correctly",
  confirm_stop_session: "Are you sure you want definitely stop this session?",
  session_connected: "Users connected",
  session_finished: "Tests completed",
  session_created: "Session started successfully",
  name_session: "name of current session",
  create_new_session: "Create new session",
  session_completed: "Completed",
  empty_session_name: "Undefined session name!",
  is_active_session: "The session is running",
  not_active_session: "The session ended",
  session_management_dashboard: "Dashboard for the sessions creation and test administration.",
  start_session: "Get started!",
  start_teacher_guide: "Read the guide",
  start_tutorial: "Launch tutorial",
  ops_game: "OPS! Your Opinion, Beyond Prejudice, Against Stereotypes!",
  teachers_guide: "Educational Materials",
  teachers_guide_exp: "Teachers Guide and useful materials and carry out the educational project.",
  teachers_guide_game: "Teachers Guide for the platform utilization and data analysis",
  tutorial_exp: "Before starting the test, try our tutorial!",
  show_report: "Show report",
  survey_completed: "Thank you for completing the test!",
  survey_saveusercode: "Copy your user code, you will use it to identify your results inside the group.",
  share: "Share",
  tutorial: "Tutorial",
  session: "Session",
  scopes: "Scopes",
  guide: "Learning Resources",
  show_qr: "Show QR code",
  show_carousel: "Show test steps",
  link_copied: "Link copied",
  code_copied: "Code copied",
  download_results: "Download data",
  paragraph: "On this dedicated page you can find the teaching materials for the implementation of the entire educational project.",
  download_faces:"Download dataset of faces",
  download_faces_classification:"Download Excel file with face classification",
  download_faces_carat:"Download the Excel file with the geometric characteristics of the faces in the dataset",
  download_video_pdf:"Watch the video tutorials for data analysis (link in pdf file)",
  session_name_tips: "Tip: insert a name that could remind the class and the date related to the test.",
  close_to_return_menu: "Close to return main menu",
  thanks: "Thanks!",
  select_an_option: "Please select an option from the dropdown menu.",
  ticket_parameters_not_found: "Ticket parameters not found",
  troisi_parameters_not_found: "Mail parameters not found",
  error_new_ticket_creation: "An error occurred while creating a new ticket",
  error_ticket_hubspot_management: "New ticket is created, but went wrong during the operations made by our portal",
  error_ticket_file_attachment: "New ticket is created, but it's not possibile to attach the file specified",
  session_expired: "Session expired",
  refreshing_session_wait: "Refreshing session, please wait",
  go_to_public_test: "Go to public test",
  teachers_area: "Teachers Area",
  start_test: "Start test",
  dismiss: "Dismiss",
  
  // user profiling
  what_is_your_genre: "What is your genere?",
  what_is_your_age: "In which year are you born?",
  where_are_you_born: "Where are you born?",
  where_are_you_living_now: "Where are you living now?",
  des_education_degree: "Education Grade",
  insert_major_grade: "Insert your major grade, even if you don't have already obtained the title",
  what_is_your_country: "What is your country?",
  since_from_you_live_in: "Since from you live in ",
  what_is_your_region: "From which region?",
  tell_something_about_you: "The test is almost completed! Before finishing, tell us something more about you",
  choose_phenotype: "In which phenotype you identify most?",
  thanks_for_partecipating: 
    `Thanks for partecipating! Before viewing the results, you should know that the faces we just showed you can be divided
    into 6 phenotypes based on characteristics such as skin color, eye shape and hair texture. Here they are:`,
  attention: "Pay attention!",
  disclaimer_phenotype_explanation: 
    `The names of the phenotypes correspond to the macro-areas of the world from which each phenotype originates,
    but nowadays characteristics such as skin color or eye shape don't tell us anything about a person's origins!`,

  // Profiling Options
  M: "M",
  F: "F",
  Other: "Other",
  NoResponse: "Rather not Answer",
  Elementary: "Elementary",
  MiddleSchool: "Middle School",
  Diploma: "Diploma",
  BachelorDegree: "Bachelor Degree",
  MasterDegree: "Master Degree",
  SingleCycleMasterDegree: "Single-Cycle Master Degree",
  MasterOrDoctoralDegree: "Master/PhD Degree",
  LessThanYear: "Less than one year",
  LessThanThreeYears: "Less than 3 year",
  LessThanFiveYears: "Less than 5 year",
  LessThanTenYears: "Less than 10 year",
  MoreThanTenYears: "More than 10 year",
  EastAsian: "East Asiatic",
  SouthAsian: "Sud Asiatic",
  SubSaharan: "Sub-saharan",
  Caucasian: "Caucasian",
  LatinAmerican: "Latin-american",
  Arabian: "Arabian",
  
  // public results
  phenotype_analysis: "Phenotype Analysis",
  gender_analysis: "Gender Analysis",
  test_finished_check_results: "Test is finished! Here the summary of your responses, compared with the responses of people who completed the test like you did",
  group_most: "The group most",
  for_you: "for you",
  users_voted_this: "Users voted this",
  group_least: "The group least",
  global_results: "Results of All Users",
  phenotype_adjective_gender_analysis: "Phenotype and gender analysis",
  go_to_results: "Go to results",
  continue: "Continue",
  proceed: "Proceed",
  confirm: "Confirm",
  results_will_go_forever: 
    `Results won't be comparable by confirming this action. You really want to stop the visualization?`,
  gender_analysis_tooltip: "Analyze global responses, based on faces gender",
  phenotype_analysis_tooltip: "Analyze your responses, based on faces phenotype",
  results_explanation: "These graphs are obtained by taking into account the responses of all the users who have participated in the test so far.",

  // EndTest
  endTest_first_paragraph: 
    `Al gioco che hai appena fatto hanno già partecipato altre centinaia di utenti. Ad ognun* di loro è capitato 
    di votare sistematicamente alcuni fenotipi o generi come più belli, intelligenti, ricchi, o migranti di altri. È successo anche a te?`,
  endTest_second_paragraph:
    ` è stata creata proprio con questo obiettivo: analizzare insieme i nostri unconscious bias, cioè i pregiudizi 
    che tutt* inconsciamente abbiamo, perché li assorbiamo sin dall’infanzia dalla società in cui viviamo.`,
  endTest_third_paragraph:
    `Vuoi un esempio? Pensiamo alla parola `,
  endTest_fourth_paragraph: "Do you want to know more about how to recognize and overcome unconscious bias? Follow the campaign",
  did_you_ever_think: "Did you ever think that",
  endTest_first_point: "is it not just those who arrive in Italy, but also those who leave Italy to work or study abroad?",
  endTest_second_point: `Did you know that the majority of migrant people in Italy are of Caucasian phenotype and Christian religion?`,
  endTest_third_point: "Did you know that the Italian population is made up of all phenotypes, and not just white people?",
  migrant: "migrant",
  return_home: "Return home",
  click: "Click",
  here: "here",
  how_to_do_it: "and find out how",
  be_part_of_change: "and become part of the change",
  how_can_i_help: "How can I help?",
  endTest_fifth_paragraph: "If you witness or experience episodes of discrimination, you can contact the UNAR toll-free number - National Anti-Racial Discrimination Office",
  invite_others_to_participate: "Invite other people to participate!",

  // StartGame
  start: "Start",
  test_introduction: "The app you are about to test is an introductory tool to learn about one of the aspects related to the world of non-discrimination: the ",
  test_introduction_two: "Recognizing and overcoming one's own biases is a way to better understand and fight discrimination: often, in fact, ",
  test_introduction_three: "we judge people automatically ",
  test_introduction_four: "without even realizing it. And this is both a reflection and driver of systemic inequalities that exist in our society.",
  test_introduction_five: "The game has ",
  test_introduction_six: `in each round, we will show you an adjective (e.g. "optimistic") and a series of faces in sequence. Under every face there will be `,
  two_buttons: "two buttons: ",
  test_introduction_eight: `one green and one red. If at first glance the face seems to reflect the adjective shown, click on the green button;
    otherwise click on the red one. It's easier said than done: follow the `,
  test_introduction_nine: "for more informations!",
  test_introduction_ten: "What are you waiting for? Take our test and share your opinion!",
  disclaimer_unconscious_bias: "The test detects prejudices rooted in today's society so it could give sensitive results for people who have experienced racism and gender discrimination.",
  start_caps_lock: "I HAVE READ AND I WANT TO START",

  // MidGame
  ready_to_see_results: "Are you ready to find out what unconscious biases you have?",
  think_to_these_questions: "Now that you see the results, try keeping these questions in mind:",
  phenotype_question: "Which phenotypes and gender did you rate positively? And which ones in a negative way?",
  unconscious_bias_question: "Reflect on the things you see and/or hear every day, what could have influenced your results?",
  disclaimer_responses: `We also asked ourselves these questions: you will find our answers after finishing the test. But be careful!
  Take a screenshot of your results before clicking “Continue”: after that you will no longer be able to view them!`,

  // Loaders
  loading_results: "Loading results...",

  ...en_faq,
  ...en_form,
};

export default en;
