import styled from "styled-components";

const Button = styled.button`
  display: block;
  border-radius: 20px;
  background-color: ${(props) =>
    props.info ? "rgb(0, 71, 92)" : props.up ? "#57f557" : "red"};
  color: ${(props) => (props.info ? "white" : props.up ? "white" : "white")};
  padding: 0.7em ${20 / 16}em;
  font-weight: 700;
  transition: scale 0.3s linear;
  font-size: 1.3em;
  z-index: 1;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
  }

  i {
    color: ${(props) => (props.up ? "white" : "white")};
    margin-left: 20px;
  }

  &:active {
    opacity: 0.4;
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

export default Button;
