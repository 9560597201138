import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";

export const addLicense = () => {
  am5.addLicense("AM5C328990290");
}

export const setChartColor = (item) => {
  item.get("colors").set("colors", [
    am5.color("rgb(206,96,76)"),      // red -> F
    am5.color("rgb(30,144,255)"),     // dodger blue -> M
    am5.color("rgb(0, 60, 80)"),
    am5.color("rgb(238,130,168)"),    // pink -> F
    am5.color("rgb(90, 170, 149)"),
    am5.color("rgb(134, 168, 115)"),
    am5.color("rgb(152,151,102)"),
    am5.color("rgb(187, 159, 6)"),
    am5.color("rgb(222,164,54)"),
    am5.color("rgb(234,151,59)"),
    am5.color("rgb(234,120,59)"),
    am5.color("rgb(8, 127, 140)"),
    am5.color("rgb(232,131,219)"),
    am5.color("rgb(166,101,183)"),
    am5.color("rgb(109,97,150)"),
    am5.color("rgb(80,103,121)"),
    am5.color("rgb(67,80,107)"),
    am5.color("rgb(89,97,115)"),
  ]);
}

const defaultZoomSettings = {
  panX: true,
  panY: true,
  wheelX: "panX",
  wheelY: "zoomX"
}
export const getXYChart = (root, zoomSettings=defaultZoomSettings) => {
  let chart = root.container.children.push(am5xy.XYChart.new(root, zoomSettings));

  setChartColor(chart)
  return chart
}

export const getPieChart = (root) => {
  return root.container.children.push(am5percent.PieChart.new(root, {
    layout: root.horizontalLayout,
    innerRadius: am5.percent(50)
  }))
}


export const getRenderAxisX = (root, grid = true) => {
  let xRenderer = am5xy.AxisRendererX.new(root, {});
  if (!grid) {
    xRenderer.grid.template.set("forceHidden", true);
  }
  xRenderer.labels.template.setAll({
    minPosition: 0.05,
    maxPosition: 0.95
  })
  return xRenderer
}

export const getRenderAxisY = (root, grid = true, inversed=false) => {
  let yRenderer = am5xy.AxisRendererY.new(root, {
    inversed: inversed
  })
  if (!grid) {
    yRenderer.grid.template.set("forceHidden", true);
  }
  yRenderer.labels.template.setAll({
    minPosition: 0.05
  })
  return yRenderer
}

export const addCursor = (root, chart, x = true, y = false) => {
  let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
  cursor.lineX.set("visible", x);
  cursor.lineY.set("visible", y);
}

export const getTooltip = (root, text, percentage = false) => {
  let tooltip = am5.Tooltip.new(root, {
    getFillFromSprite: false,
    autoTextColor: false,
    labelText: `${text}${percentage ? ' %' : ''}`
  });

  tooltip.get("background").setAll({
    fill: am5.color("#bfbfc1"),
    fillOpacity: 0.8
  });

  tooltip.label.setAll({
    fill: am5.color("rgb(0, 60, 80)")
  });

  return tooltip
}

export const addSeriesBullet = (root, series, radius = 4, strokeWidth = 2) => {
  series.bullets.push(function () {
    let circle = am5.Circle.new(root, {
      radius: radius,
      fill: series.get("fill"),
      stroke: root.interfaceColors.get("background"),
      strokeWidth: strokeWidth
    });

    return am5.Bullet.new(root, {
      sprite: circle
    });
  });
}