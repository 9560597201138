import React from "react";
import Logo from "../../components/Logo";

const AuthHeader = ({ title }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="flex justify-center w-full">
          <Logo height="h-40" imagePath={process.env.PUBLIC_URL + "/ops-screen.svg"} />
      </div>
        <div className="flex justify-around w-full">
            <Logo height="h-32" imagePath={process.env.PUBLIC_URL + "/unicef-logo.svg"} />
        </div>
      <h2 className="text-center text-am-600 text-3xl font-bold text-gray-900">
        {title}
      </h2>
    </div>
  );
};

export default AuthHeader;
