class UserLocalStorage {
  set(key, v) {
    localStorage.setItem(key, JSON.stringify(v));
    return this.get(key);
  }

  get(key) {
    const d = localStorage.getItem(key) || "{}";
    return JSON.parse(d);
  }

  purge(key) {
    localStorage.removeItem(key);
  }
}

export class UserSessionStore {
  constructor(storage, key) {
    this.storage = storage || new UserLocalStorage();
    this.storage_key = key || "gamedata";
  }

  _getStore(id, k) {
    const gd = this.storage.get(this.storage_key);
    return gd[id] ? gd[id][k] : undefined;
  }

  _setStore(id, k, v) {
    const s = this.storage.get(this.storage_key);
    if (!s[id]) {
      s[id] = {};
    }
    s[id][k] = v;
    return this.storage.set(this.storage_key, s);
  }

  getUserData(session_id) {
    return this._getStore(session_id, "user_data");
  }

  setUserData(session_id, user_data) {
    this._setStore(session_id, "user_data", user_data);
  }

  getCompleted(session_id) {
    return this._getStore(session_id, "completed");
  }

  setCompleted(session_id, isCompleted) {
    this._setStore(session_id, "completed", isCompleted);
  }

  purge() {
    this.storage.purge(this.storage_key);
  }
}

export default UserSessionStore;