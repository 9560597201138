import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

// Model
import { TypesPublicUserPhenotypeProfiling } from './PublicUserProfiling.types';
import { PublicUserPhenotypeOptions } from '@components/PublicSurvey/PublicSurvey.types';
import { GroupedImages } from './PublicUserProfiling.types';
import { TypesImageProps } from './PublicUserProfiling.types';

// Components
import { PageSection } from '@ui-components/Container';

// Services
import { groupImagesByPhenotype } from './PublicUserProfiling.services';


function PublicUserPhenotypeProfiling({
  userProfilingData,
  setUserProfilingData,
  images,
}: TypesPublicUserPhenotypeProfiling) {
  const intl = useIntl();
  const [imagesGroupedByPhenotype, setImagesGroupedByPhenotype] = useState<GroupedImages>();

  useEffect(() => {
    if (images) {
      const imagesWithoutTutorial = images.filter(img => img.des_phenotype !== null);
      const groupedImages: GroupedImages = groupImagesByPhenotype(imagesWithoutTutorial);

      setImagesGroupedByPhenotype(groupedImages);
    }
  }, [images])

  return (
    <div>
      <PageSection title={intl.formatMessage({ id: "choose_phenotype" })} resultsPage={true}>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6'>
          {
            imagesGroupedByPhenotype ?
            Object.keys(imagesGroupedByPhenotype).map(phenotype => {
              return <div key={phenotype} className='mx-auto'>
                <div
                  className='grid grid-cols-2 gap-y-2 gap-x-3 cursor-pointer p-2'
                  onClick={() => setUserProfilingData({
                    ...userProfilingData,
                    des_phenotype: userProfilingData?.des_phenotype && userProfilingData.des_phenotype === phenotype ? 
                      undefined :
                      phenotype as PublicUserPhenotypeOptions
                  })}
                  style={{ border: userProfilingData?.des_phenotype === phenotype ? '5px solid #1cabe2' : '5px solid white', borderRadius: '20px' }}
                >
                  <div className='col-span-2 text-center font-bold'>{intl.formatMessage({id: phenotype})}</div>
                  {
                    imagesGroupedByPhenotype[phenotype].map(image => {
                      return <PhenotypeImage
                          key={image.file_name}
                          image={`${process.env.PUBLIC_URL}/generated_faces/${image.file_name}`}
                        />
                    })
                  }
                </div>
              </div>
            })
            :
            null
          }
        </div>
      </PageSection>
    </div>
  );
}


const PhenotypeImage = styled.div`
  height: 100px;
  background-color: #8080803d;
  width: 100px;
  position: relative;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-image: url(${(props: TypesImageProps) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export default PublicUserPhenotypeProfiling;