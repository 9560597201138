import { useEffect, useState } from "react";
import { AggregateResponseReturnSchema } from "./AggregatedResults.types";
import { api } from "@services/apiRequest";

function useAggregatedResultsFetch(uid_user?: string) {
  const [aggregatedResults, setAggregatedResults] = useState<AggregateResponseReturnSchema[]>();
  
  useEffect(() => {
    async function fetchAggregatedResults() {
      const queryParams = new URLSearchParams();

      if (uid_user) {
        queryParams.append('uid_user', uid_user);
      }
      const { data: results } = await api.get<AggregateResponseReturnSchema[]>(
        `/aggregate_responses?${queryParams}`, {
          publicRequest: true
        }
      );
      setAggregatedResults(results);
    }

    fetchAggregatedResults();
  }, [uid_user]);
  
  return aggregatedResults;
}

export default useAggregatedResultsFetch;