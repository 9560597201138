import React from "react";
import { TypesCheckbox } from "./ui-components.types";

const Checkbox = ({ 
  readOnly = true,
  id,
  name,
  checked,
  classNames = "",
  onChange,
}: TypesCheckbox) => {
  return <input
    type="checkbox"
    id={id}
    name={name}
    checked={checked}
    readOnly={readOnly}
    onChange={onChange || (() => null)}
    className={`focus:ring-am-500 h-4 w-4 text-am-600 border-gray-300 rounded ${classNames}`}
  />

}

export default Checkbox;