import { GroupedImages } from "@components/PublicUserProfiling/PublicUserProfiling.types"
import { AggregateResponseReturnSchema } from "@hooks/useAggregatedResults/AggregatedResults.types"
import { ResultsGenderScore } from "@hooks/useAggregatedResults/AggregatedResults.types"
import { TestImageSchema } from "@hooks/useImages/Images.types"

export interface TypesPublicResultsPage {
  uid_user: string
}

export interface TypesPublicResultsPhenotypeSection {
  imagesGrouped: GroupedImages,
  globalResults: AggregateResponseReturnSchema[],
  userResults: AggregateResponseReturnSchema[],
}
export interface TypesPublicResultsAdjectiveSection extends TypesPublicResultsPhenotypeSection {
  adjective: string,
}

export interface TypesPublicResultsGender {
  globalResults: AggregateResponseReturnSchema[],
}

export interface GlobalAdjectiveByGenderResults {
  adjective: string,
  F: number,
  M: number,
}

export interface TypesPublicResultsGenderPhenotypeAdjective {
  adjective: string,
  jsn_aggregation: Record<string, ResultsGenderScore>,
}

export interface GlobalPhenotypeByGenderResults {
  phenotype: string,
  F: number,
  M: number,
}

export interface PhenotypeScore {
  phenotype: string,
  score: number,
}

export const ResultsTabs = [
  "phenotype_analysis",
  "gender_analysis",
]

export interface TypesCoupleImagesPhenotype {
  phenotypeLabel: string,
  imagesArray: TestImageSchema[],
}