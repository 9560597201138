import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getTooltip, getXYChart, addCursor, getRenderAxisY, addLicense} from "./commons";


function VerticalBarChart({id, data, categoryField, percentage = false, rotateLabel = false}) {
  useLayoutEffect(() => {
    if (data) {
      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      addCursor(root, chart)

      let legend = chart.rightAxesContainer.children.push(
        am5.Legend.new(root, {
          centerY: am5.p50,
          y: am5.p50,
        })
      );

      // Create axes
      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 20
      })

      xRenderer.labels.template.setAll({
        rotation: rotateLabel ? -20 : 0,
        centerY: rotateLabel ? am5.p50 : undefined,
        centerX: rotateLabel ? am5.p100 : undefined,
      });

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: categoryField,
        renderer: xRenderer
      }));

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: getRenderAxisY(root),
        min: 0,
        max: percentage ? 100 : undefined
      }))

      // Add series
      function createSeries(field, name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: categoryField,
          tooltip: getTooltip(root, "[bold]{name}[/] \n {categoryX}: {valueY}", percentage)
        }))

        series.data.setAll(data);
        series.appear(1000);

        legend.data.push(series);
      }

      if (data.length) {
        Object.keys(data[0]).forEach(key => {
          if (key !== categoryField) {
            createSeries(key, key)
          }
        })
      }

      return () => {
        root.dispose()
      }
    }

  }, [data, id, percentage, categoryField, rotateLabel])

  return <div id={id} className="ml-96 sm:ml-32 md:ml-0" style={{minWidth: "800px", minHeight: "350px"}}/>    // FIXME: this ml is hardcoded to show entire graph on mobile mode
}

export default React.memo(VerticalBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
