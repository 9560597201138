import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

// Model & Hooks
import { ResultsTabs, TypesPublicResultsPage } from './TypesPublicResultsPage.types';
import { GroupedImages } from '@components/PublicUserProfiling/PublicUserProfiling.types';
import useImagesPhenotypeFetch from '@hooks/useImages/useImagesPhenotypeFetch';
import useAggregatedResultsFetch from '@hooks/useAggregatedResults/useAggregatedResultsFetch';
import { AggregateResponseReturnSchema } from '@hooks/useAggregatedResults/AggregatedResults.types';

// Components
import Spinner from '@ui-components/Spinner';
import Button from '@ui-components/Button';
import Tabs from '@ui-components/Tabs';
import PublicResultsPhenotypeSection from './PublicResultsPhenotype/PublicResultsPhenotypeSection';
import PublicResultsGender from './PublicResultsGender/PublicResultsGender';
import Modal from '@ui-components/Modal';

// Services
import { groupImagesByPhenotype } from '@components/PublicUserProfiling/PublicUserProfiling.services';


function PublicResultsPage({
  uid_user,
}: TypesPublicResultsPage) {
  const intl = useIntl();
  const history = useHistory();

  const images = useImagesPhenotypeFetch();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [imagesGroupedByPhenotype, setImagesGroupedByPhenotype] = useState<GroupedImages>();
  const [openedConfirmModal, setOpenedConfirmModal] = useState<boolean>(false);

  const globalResults: AggregateResponseReturnSchema[] = useAggregatedResultsFetch() as unknown as AggregateResponseReturnSchema[];
  const userResults: AggregateResponseReturnSchema[]  = useAggregatedResultsFetch(uid_user) as unknown as AggregateResponseReturnSchema[];

  const _goToEndTest = () => {
    history.push("/endTest/explanation");
  }

  useEffect(() => {
    if (images) {
      const groupedImages: GroupedImages = groupImagesByPhenotype(images);
      setImagesGroupedByPhenotype(groupedImages);
    }
  }, [images])

  const _renderSelectedTabComponent = () => {
    if (imagesGroupedByPhenotype && globalResults && userResults) {
      switch (selectedTab) {
        case 1:
          return <PublicResultsGender 
            globalResults={globalResults}
          />
        default:
          return <PublicResultsPhenotypeSection 
            imagesGrouped={imagesGroupedByPhenotype}
            globalResults={globalResults}
            userResults={userResults}
          />
      }
    }
  }

  return (
    <div>
      {
        imagesGroupedByPhenotype && globalResults && userResults ?
          <div className="mt-4 flex flex-col gap-y-5 items-center">
            <div className='font-bold text-xl text-center max-w-xl'>{intl.formatMessage({ id: "test_finished_check_results" })}</div>
            
            <div className='flex gap-x-2 items-center'>
              <Tabs
                tabs={ResultsTabs.map(tabname => ({name: intl.formatMessage({ id: tabname }), tooltipText: intl.formatMessage({ id: `${tabname}_tooltip` })}))}
                active={selectedTab}
                setActiveTab={setSelectedTab}
                tooltip={true}
              />
            </div>
            {_renderSelectedTabComponent()}

            <div className='mt-8 mb-8'>
              <Button onClick={() => setOpenedConfirmModal(true)}>
                {intl.formatMessage({ id: "stop_session" })}
              </Button>
            </div>

            <Modal 
              opened={openedConfirmModal}
              title={intl.formatMessage({ id: "confirm"})}
              description={intl.formatMessage({id: "results_will_go_forever" })}
              onExit={() => setOpenedConfirmModal(false)}
              onDismiss={() => {
                setOpenedConfirmModal(false);
              }}
              onConfirm={() => {
                _goToEndTest();
              }}
              confirmText="OK"
              dismissText={intl.formatMessage({id: "dismiss" })}
            />
          </div> :
          <Spinner 
            styleType='loader'
            description={intl.formatMessage({ id: "loading_results" })}
          />
      }
    </div>
  );
}

export default PublicResultsPage;