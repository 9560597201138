import React, { Fragment } from "react";
import styled from "styled-components";

const Summary = ({ result, slides, images }) => {
  const adjectives = slides.filter((s) => !s.tutorial && s.id_adjective);
  const steps = adjectives.reduce((acc, ad) => {
    const r = result.filter((res) => res.id_adjective === ad.id_adjective);

    return [
      ...acc,
      {
        ...ad,
        result: r.map((res) => {
          const image = images.find((i) => i.id === res.id_image);

          return {
            ...res,
            image,
          };
        }),
      },
    ];
  }, []);

  return (
    <SummaryBox>
      {steps.map((step) => (
        <Fragment key={step.id_adjective}>
          <Adjective className="adjective">{step.adjective}</Adjective>
          <WrapperImages className="wrapper">
            {step.result
              .filter(({ flg_response }) => !!flg_response)
              .map(({ image, flg_response }, index) => (
                <Image
                  className="summary-image"
                  response={flg_response}
                  key={index}
                >
                  <Img
                    src={`${process.env.PUBLIC_URL}/generated_faces/${image.file_name}`}
                  />
                </Image>
              ))}
          </WrapperImages>
        </Fragment>
      ))}
    </SummaryBox>
  );
};

const Img = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Image = styled.div`
  border-radius: 10px;
  width: 60px;
  height: 60px;
  border: 3px solid ${(props) => (props.response ? "#57f557" : "red")};
  position: relative;

  margin-right: 6px;
  margin-bottom: 6px;
`;

const WrapperImages = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
`;

const Adjective = styled.p`
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 1em;
  font-weight: 700;
  color: #5e5e5e;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export default Summary;
