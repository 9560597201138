import React, { Fragment, useContext } from "react";
import { createGlobalStyle } from "styled-components";
import Logo from "./Logo";
import {useIntl, FormattedMessage} from "react-intl";

import Button from "../ui-components/Button";
import GenericTooltip from "./GenericTooltip";
import { NotificationsContext } from "../ui-components/Notifications";

const CompletePage = ({ session_uuid, user_code, user_uuid }) => {
  const intl = useIntl();
  const { push } = useContext(NotificationsContext);

  return (
    <Fragment>
      <GlobalStyle />
      {/* <div className=" min-h-full overflow-y-auto pt-16 pb-12 flex flex-col bg-white fixed inset-0"> */}
      <div className="flex-shrink-0 flex justify-center">
         <Logo height="h-32" url="/unicef-logo.svg" />
      </div>
      <main className="flex-grow flex flex-col justify-around max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div id="complete-title" className="pb-10">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {intl.formatMessage({id: "survey_completed"})}
            </h1>
            <p className="my-10">
              {intl.formatMessage({id: "survey_saveusercode"})}
            </p>
            <div className="flex items-center justify-center gap-x-2">
              <GenericTooltip 
                body={
                  <div 
                    onClick={() => {
                      navigator.clipboard.writeText(user_code);
                      push({title: intl.formatMessage({id: "code_copied"}), type: "success"}) 
                    }} 
                    className="bg-am-500 text-white w-auto inline-block rounded-xl p-5 text-xl"
                  >
                    {user_code}
                  </div>
                }
                text={intl.formatMessage({id: 'copy_id'})}
                size={200}
                placement="top"
              />
            </div>
          </div>
        </div>

        <div className="text-center">
          <a className="font-bold text-blue" 
            href={`/results/${session_uuid}/${user_uuid}`}
            target="_blank" rel="noreferrer"
          >
            <Button>
              <FormattedMessage id="show_report" />  
            </Button>
          </a>
        </div>
      </main>
      {/* </div> */}
    </Fragment>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: auto !important;
  }
  
`;

export default CompletePage;
