import it_faq from "./faq/it_faq";
import it_form from "./form/it";

const it = {
  entity_user: "Gestione delle utenze in sistema",
  entity_role: "Gestione dei ruoli di autorizzazione in sistema",
  name_and_surname: "Nome e cognome",
  entity_audit: "Gestione dell'audit",
  language_it: "Italiano",
  language_en: "English",
  language: "Lingua",
  Home: "Home",
  disabled_user: "Utente disabilitato",
  enter_name_and_surname: "Inserisci nome e cognome",
  enter_name_or_email: "Inserisci email e nome",
  need_to_select_a_role: "Devi selezionare un ruolo",
  delete_user: "Eliminare l'utenza?",
  disabled: "Disabilitato",
  user_updated_successfully: "Utenza aggiornata con successo",
  user_deleted_successfully: "Utenza eliminata con successo",
  user_created_successfully: "Utente creato con successo",
  description: "Descrizione",
  role_name: "Nome ruolo",
  add_role_name: "Inserisci il nome del ruolo",
  add_role: "Aggiungi ruolo",
  add_user: "Aggiungi utente",
  deleted_role: "Ruolo eliminato",
  updated: "Aggiornati",
  delete_element: "Eliminare l'elemento?",
  role_already_used: "Ruolo già in uso!",
  role_created_successfully: "Ruolo creato con successo",
  add: "Aggiungi",
  server_error: "Errore del server",
  confirm_password: "Conferma password",
  passwords_not_matching: "Le due password non coincidono",
  password_without_spaces: "La password non deve contenere spazi",
  password_length: "La password deve essere lunga almeno 8 caratteri",
  signup: "Registrati",
  error_while_saving: "Errore durante il salvataggio",
  authentication_failed: "Autenticazione fallita",
  send: "Invia",
  user_not_found: "Utente non trovato",
  wrong_old_password: "Vecchia password errata",
  enter_actual_password: "Inserisci password attuale",
  email: "Email",
  name: "Nome",
  scopes: "Permessi",
  role: "Ruolo",
  user_type: "Tipo utente",
  my_profile: "Il mio profilo",
  access_denied: "Accesso negato",
  password_updated_successfully: "Password modificata con successo",
  submit_password: "Invia password",
  enter_your_email_address: "Inserisci l'indirizzo email associato alla tua utenza",
  restore_password: "Recupera password",
  forgot_password: "Password dimenticata?",
  edit_password: "Modifica password",
  show_password: "Mostra password",
  form_error: "Controlla i campi del form",
  enter_email: "Inserisci email",
  missing_sender_mail: "Mail del mittente mancante",
  log_into_your_account: "Entra nel tuo account",
  wrong_email_or_password: "Email o password sbagliati",
  unregistered_email: "Email non registrata",
  forgot_password_msg: "Mail inviata! Se non ricevi alcuna notizia da noi nei prossimi 15 minuti, controlla di aver inserito l'indirizzo e-mail corretto e controlla nella cartella spam.",
  roles: "Ruoli",
  roles_displayed_rows: "{from}-{to} di {count} ruoli",
  users: "Utenti",
  users_displayed_rows: "{from}-{to} di {count} utenti",
  components: "Componenti",
  maps: "Mappe",
  tables: "Tabelle",
  graphs: "Grafici",
  users_settings: "Gestione utenze",
  welcome: "Benvenut*",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Seleziona uno o più file",
  success_ticket_sent: "Il ticket è stato aggiunto correttamente, prenderemo in carico la richiesta il prima possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Dimensione massima superata",
  customer_portal_loading_failed: "Errore durante il caricamento del link al portale cliente",
  week_datepicker: "Settimana ",
  week_datepicker_not_selected: "Datepicker settimanale",
  daily_datepicker_not_selected: "Datepicker giornaliero",
  sessions: "Gestione Sessioni",
  entity_session: "Gestione Sessioni",
  page_not_found: "Pagina non trovata",
  go_back_home: "Torna alla home",
  page_not_found_message: "Siamo spiacenti ma non abbiamo trovato quello che stavi cercando",
  gamesession_start: "Inizia Test",
  session_started_at: "Avviata",
  session_created_at: "Creata",
  session_stopped_at: "Conclusa",
  copy_id: "Clicca per copiare il codice",
  copy_test_link: "Copia link",
  stop_session: "Termina test",
  session_stopped: "Test concluso con successo",
  session_started: "Test avviato con successo",
  confirm_stop_session: "Sei sicur* di voler terminare definitivamente questa sessione?",
  session_connected: "Numero utenti connessi",
  session_finished: "Numero test completati",
  create_new_session: "Inizia Nuova Sessione",
  session_management_dashboard: "Dashboard per la creazione delle sessioni e somministrazione del test.",
  session_created: "Sessione avviata con successo",
  name_session: "Nome della nuova sessione",
  session_completed: "Hanno completato",
  empty_session_name: "Nome sessione non definito!",
  is_active_session: "La sessione è attiva",
  not_active_session: "La sessione è terminata",
  start_session: "Gestisci",
  start_teacher_guide: "Vai ai materiali",
  start_tutorial: "Lancia il tutorial",
  ops_game: "OPS! La tua Opinione, oltre ogni Pregiudizio, contro gli Stereotipi!",
  teachers_guide: "Materiali Didattici",
  teachers_guide_exp: "Guida docenti e materiali utili a realizzare il progetto educativo.",
  teachers_guide_game: "Guida docenti per l'utilizzo della piattaforma e l'analisi dati",
  tutorial_exp: "Prima di iniziare il test, prova il nostro tutorial!",
  see_results: "Segui questo link per vedere i risultati",
  survey_completed: "Grazie per aver completato il Test!",
  survey_saveusercode: "Segnati il tuo codice, lo userai per identificare i tuoi risultati tra quelli di gruppo",
  share: "Condividi",
  show_report: "Mostra il report",
  show_qr: "Mostra codice QR",
  show_carousel: "Mostra i passi del test",
  link_copied: "Link copiato",
  code_copied: "Codice copiato",
  tutorial: "Tutorial",
  session: "Sessione",
  guide: "Materiali Didattici",
  download_results: "Scarica i dati",
  paragraph:"In questa pagina dedicata puoi trovare i materiali didattici per la realizzazione dell'intero progetto educativo.",
  download_faces:"Scarica il dataset dei volti ",
  download_faces_classification:"Scarica il file Excel con la classificazione dei volti",
  download_faces_carat:"Scarica il file Excel con le caratteristiche geometriche dei volti del dataset",
  download_video_pdf:"Guarda i video tutorial per l'analisi dei dati (link presente nel file pdf)",
  session_name_tips: "Consiglio: inserisci un nome che possa fare riferimento alla classe e alla data in cui viene svolto il test.",
  close_to_return_menu: "Chiudi per tornare al menu principale",
  thanks: "Grazie!",
  select_an_option: "Seleziona un'opzione dal menu a tendina.",
  ticket_parameters_not_found: "Parametri di invio ticket non presenti",
  troisi_parameters_not_found: "Parametri di invio mail non presenti",
  error_new_ticket_creation: "Errore imprevisto nella creazione di un nuovo ticket",
  error_ticket_hubspot_management: "É stato creato un nuovo ticket, ma qualcosa è andato storto durante le operazioni effettuate dal nostro portale",
  error_ticket_file_attachment: "É stato creato un nuovo ticket, ma non è possibile allegare il file specificato",
  session_expired: "Sessione scaduta",
  refreshing_session_wait: "Rinnovo della sessione in corso, attendere...",
  go_to_public_test: "Vai al test pubblico",
  teachers_area: "Area Docenti",
  start_test: "Inizia Test",
  dismiss: "Annulla",
  
  // user profiling
  what_is_your_genre: "Qual è il tuo genere?",
  what_is_your_age: "In che anno sei nat*?",
  where_are_you_born: "Dove sei nat*?",
  where_are_you_living_now: "Dove vivi adesso?",
  des_education_degree: "Grado di istruzione",
  insert_major_grade: "Inserisci il grado più alto, anche se non hai ancora ottenuto il titolo di studio",
  what_is_your_country: "Qual è il tuo paese?",
  since_from_you_live_in: "Da quanto tempo vivi in ",
  what_is_your_region: "Da quale regione provieni?",
  tell_something_about_you: "Il test è quasi completato! Prima di terminare, dicci qualcosa in più su di te: premi CONTINUA per vedere i risultati del test",
  choose_phenotype: "In quale fenotipo ti identifichi di più?",
  thanks_for_partecipating: 
    `Grazie per aver partecipato! Prima di visualizzare i risultati, devi sapere che i volti che ti abbiamo appena mostrato sono suddivisibili 
    in 6 fenotipi in base a caratteristiche come il colore della pelle, la forma degli occhi e la texture dei capelli. Eccoli qui:`,
  attention: "Attenzione!",
  disclaimer_phenotype_explanation: 
    `I nomi dei dei fenotipi corrispondono alle macro-aree del mondo di cui ogni fenotipo è originario,
    ma al giorno d’oggi caratteristiche come il colore della pelle o la forma degli occhi non ci dicono niente sulla provenienza di una persona!`,

  // Profiling Options
  M: "M",
  F: "F",
  Other: "Altro",
  NoResponse: "Preferisco non Rispondere",
  Elementary: "Elementari",
  MiddleSchool: "Terza Media",
  Diploma: "Diploma",
  BachelorDegree: "Laurea Triennale",
  MasterDegree: "Laurea Magistrale",
  SingleCycleMasterDegree: "Laurea Magistrale a Ciclo Unico",
  MasterOrDoctoralDegree: "Master/Dottorato",
  LessThanYear: "Meno di un anno",
  LessThanThreeYears: "Meno di 3 anni",
  LessThanFiveYears: "Meno di 5 anni",
  LessThanTenYears: "Meno di 10 anni",
  MoreThanTenYears: "Più di 10 anni",
  EastAsian: "Est Asiatico",
  SouthAsian: "Sud Asiatico",
  SubSaharan: "Sub-sahariano",
  Caucasian: "Caucasico",
  LatinAmerican: "Latino-americano",
  Arabian: "Arabo",

  // public results
  phenotype_analysis: "Analisi per Fenotipo",
  gender_analysis: "Analisi per Genere",
  test_finished_check_results: 
    `Il test è terminato! Ecco il resoconto delle tue risposte, messe a confronto con le risposte 
    delle persone che hanno completato il test come te`,
  group_most: "Il gruppo più",
  for_you: "per te",
  users_voted_this: "L* utenti hanno votato questo",
  group_least: "Il gruppo meno",
  global_results: "Risultati di tutt* l* Utenti",
  phenotype_adjective_gender_analysis: "Analisi per genere e fenotipo",
  go_to_results: "Vai ai risultati",
  continue: "Continua",
  proceed: "Procedi",
  confirm: "Conferma",
  results_will_go_forever: 
    `I risultati non saranno più confrontabili confermando questa azione. Vuoi davvero interromperne la visualizzazione?`,
  gender_analysis_tooltip: "Valuta le risposte globali in base al genere dei volti",
  phenotype_analysis_tooltip: "Valuta le tue risposte in base al fenotipo dei volti",
  results_explanation: "Questi grafici sono ottenuti tenendo conto delle risposte di tutt* l* utenti che hanno partecipato al test finora.",

  // EndTest
  endTest_first_paragraph: 
    `Al gioco che hai appena fatto hanno già partecipato altre centinaia di utenti. Ad ognun* di loro è capitato 
    di votare sistematicamente alcuni fenotipi o generi come più belli, intelligenti, ricchi, o migranti di altri. È successo anche a te?`,
  endTest_second_paragraph:
    ` è stata creata proprio con questo obiettivo: analizzare insieme i nostri unconscious bias, cioè i pregiudizi 
    che tutt* inconsciamente abbiamo, perché li assorbiamo sin dall’infanzia dalla società in cui viviamo.`,
  endTest_third_paragraph:
    `Vuoi un esempio? Pensiamo alla parola `,
  did_you_ever_think: "Hai mai pensato che",
  endTest_fourth_paragraph: "Vuoi saperne di più su come riconoscere e superare gli unconscious bias? Segui la campagna",
  endTest_first_point: "non è solo chi arriva in Italia, ma anche chi lascia l’Italia per lavorare o studiare all’estero?",
  endTest_second_point: `Sapevi che la maggior parte delle persone migranti in Italia sono di fenotipo caucasico e di religione cristiana?`,
  endTest_third_point: "Sapevi che la popolazione italiana è fatta da tutti i fenotipi, e non solo da persone bianche?",
  migrant: "migrante",
  return_home: "Ritorna alla home",
  click: "Clicca",
  here: "qui",
  how_to_do_it: "e scopri come fare",
  be_part_of_change: "e diventa parte del cambiamento",
  how_can_i_help: "Come posso aiutare?",
  endTest_fifth_paragraph: "Se assisti o vivi episodi di discriminazioni, puoi rivolgerti al numero verde dell’UNAR – Ufficio Nazionale Antidiscriminazioni Razziali",
  invite_others_to_participate: "Invita altre persone a partecipare!",
  
  // StartGame
  start: "Inizia",
  test_introduction: "L’app che stai per testare è uno strumento introduttivo per conoscere uno degli aspetti legati al mondo della non discriminazione: gli ",
  test_introduction_two: "Riconoscere e superare i propri bias è un modo per capire meglio e combattere le discriminazioni: spesso, infatti, ",
  test_introduction_three: "giudichiamo le persone in modo automatico ",
  test_introduction_four: "senza nemmeno rendercene conto. E questo è sia il riflesso che il motore di disuguaglianze sistemiche che esistono nella nostra società.",
  test_introduction_five: "Il gioco ha ",
  test_introduction_six: "in ogni round, ti mostreremo un aggettivo (es. “ottimista”) e una serie di volti in sequenza. Sotto ogni volto ci saranno ",
  two_buttons: "due pulsanti: ",
  test_introduction_eight: `uno verde e uno rosso. Se a primo impatto il volto ti sembra rispecchiare l’aggettivo mostrato, clicca sul pulsante verde; 
    altrimenti clicca su quello rosso. È più semplice a farsi che a dirsi: segui il `,
  test_introduction_nine: "per maggiori informazioni!",
  test_introduction_ten: "Cosa aspetti? Partecipa al nostro test e condividi la tua opinione!",
  disclaimer_unconscious_bias: "Il test rileva pregiudizi radicati nella società di oggi per cui potrebbe dare risultati sensibili per persone che hanno vissuto razzismo e discriminazioni di genere.",
  start_caps_lock: "HO LETTO E INIZIO IL TEST",

  // MidGame
  ready_to_see_results: "Sei pront* a scoprire quali pregiudizi inconsci hai?",
  think_to_these_questions: "Ora che vedrai i risultati, prova a tenere a mente queste domande:",
  phenotype_question: "Quali fenotipi e generi hai giudicato in modo positivo? E quali in modo negativo?",
  unconscious_bias_question: "Rifletti sulle cose che vedi e/o senti tutti i giorni, cosa può avere condizionato i tuoi risultati?",
  disclaimer_responses: `Anche noi ci siamo fatt* queste domande: troverai le nostre risposte dopo aver terminato il test. Ma attenzione! 
    Fai uno screenshot ai tuoi risultati prima di cliccare su “Continua”: dopo non potrai più visualizzarli!`,
  
  // Loaders
  loading_results: "Caricamento dei risultati...",

  ...it_faq,
  ...it_form,
};

export default it;
