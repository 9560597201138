import { IntlShape } from "react-intl";

// Components
import SearchSelect from "@ui-components/SearchSelect";
import Select from "@ui-components/Select";

// Model
import { CountrySchema, GroupedImages, PublicUserProfilingData } from "./PublicUserProfiling.types";
import { TestImageSchema } from "@hooks/useImages/Images.types";
import { TypesSearchSelectOptions } from "@ui-components/ui-components.types";
import { toUTC } from "@utils/index";
import Tooltip from "@ui-components/Tooltip";

// construct select component from enum options
interface SelectFromEnumProps<T extends Record<string, string>> {
  intl: IntlShape,
  onChange: (selectedValue: keyof T) => void,
  label: string,
  enumOptions: T,
  value: string | undefined,
  translateOptions: boolean,
  attachToLabel?: string,
  tooltipDescription?: string,
}

export const selectFromEnum = <T extends Record<string, string>>(
  props: SelectFromEnumProps<T>
) => {
  const { intl, onChange, label, enumOptions: enumValues, value } = props;
  
  const _calculateLabel = () => {
    if (props.tooltipDescription) {
      return <div className="flex items-center gap-x-2">
        {`${intl.formatMessage({ id: label })}${props.attachToLabel ?? ""}`}
        <Tooltip
          text={intl.formatMessage({ id: props.tooltipDescription })}
          size={250}
          placement="top"
        />
      </div>
    } else return `${intl.formatMessage({ id: label })}${props.attachToLabel ?? ""}`;
  }

  return <div>
    <Select 
      label={_calculateLabel()}
      value={value}
      options={[{name: "", value: ""}].concat(
        Object.values(enumValues).map(option => ({
          name: props.translateOptions ? intl.formatMessage({ id: option }) : option,
          value: option
        }))
      )}
      onChange={(selectedOption) => onChange(selectedOption.currentTarget.value)}
    />
  </div>
}

export const singleSearchSelect = (
  intl: IntlShape,
  label: string,
  value: string | number | undefined,
  options: string[],
  onChange: Function,
  translateOptions: boolean,
) => {

  const _getTranslatedValue = (value: string) => {
    if (translateOptions) {
      return intl.formatMessage({ id: value });
    } else return value;
  }

  return <div>
    <SearchSelect 
      label={intl.formatMessage({ id: label })}
      value={value ? {label: _getTranslatedValue(value.toString()), value: value} : undefined}
      options={options.map(option => ({label: _getTranslatedValue(option), value: option}))}
      onChange={(selectedOptions: TypesSearchSelectOptions[]) => {
        if (selectedOptions.length) {
          onChange(selectedOptions[selectedOptions.length - 1].value)
        } else {
          onChange(undefined);
        }
      }} 
      isMulti={true}
    />
  </div>
}

export const standardSelect = (
  intl: IntlShape,
  label: string,
  value: string | undefined,
  options: string[],
  onChange: Function,
  translateOptions: boolean,
) => {
  const _getTranslatedValue = (value: string | undefined) => {
    if (translateOptions && value) {
      return intl.formatMessage({ id: value });
    } else return value ?? "";
  }

  return <div>
    <Select 
      label={intl.formatMessage({ id: label })}
      value={_getTranslatedValue(value)}
      options={[{name: "", value: ""}].concat(options.map(option => ({name: _getTranslatedValue(option), value: option})))}
      onChange={(selectedOption) => onChange(selectedOption.currentTarget.value)}
    />
  </div>
}

export const groupImagesByPhenotype = (images: TestImageSchema[]) => {
  const groupedImages: GroupedImages = images.reduce((acc: any, image) => {
    const { des_phenotype, ...rest } = image;
    if (!acc[des_phenotype]) {
      acc[des_phenotype] = [];
    }
    acc[des_phenotype].push({ des_phenotype, ...rest });
    return acc;
  }, {});

  return groupedImages;
}


export const calculateYearOptionFromToday = () => {
  const currentYear = toUTC(new Date()).getFullYear();

  const res = [];
  for (let i = currentYear; i > currentYear - 100; i--) res.push(i.toString());

  return res;
}

export const checkUserProfilingDisabled = (countries?: CountrySchema[], userProfiling?: PublicUserProfilingData) => {
  if (userProfiling && countries) {
    if (userProfiling.des_gender === undefined) return true;
    if (userProfiling.val_year_birth === undefined) return true;
    if (userProfiling.id_country_birth === undefined) return true;
    if (userProfiling.id_country_living === undefined) return true;
    if ((userProfiling.id_country_birth !== userProfiling.id_country_living) && userProfiling.des_time_country_living === undefined) return true;
    if (["Italia", "Italy"].includes(countries.filter(x => x.id === userProfiling.id_country_living)[0].des_name) && userProfiling.des_region === (undefined || "")) return true;
    if (userProfiling.des_phenotype === undefined) return true;
    if (userProfiling.des_education_degree === undefined) return true;

    return false;
  } else return true;
}
