import React, { useState, useRef, useEffect } from "react";
import IconButton from "./IconButton";
import { TypesTooltip } from "./ui-components.types";

const Tooltip = ({ 
  children = <IconButton iconName="QuestionMarkCircleIcon" className="h-5 w-5" /> , 
  text,
  size = "extra-small",
  placement,
  customStyle = "" 
} : TypesTooltip) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [bodyWidth, setBodyWidth] = useState<number>();
  const [bodyHeight, setBodyHeight] = useState<number>();
  const [placementStyling, setPlacementStyling] = useState<React.CSSProperties>();
  const ref = useRef<HTMLDivElement>(null);
  const sizeWidth = setTooltipWidth();

  useEffect(() => {
    setBodyWidth(ref.current?.offsetWidth);
    setBodyHeight(ref.current?.offsetHeight);
  }, []);

  function setTooltipWidth() {
    switch (size) {
      case "extra-small":
        return 100;
      case "small":
        return 250;
      case "medium":
        return 400;
      case "large":
        return 550;
      default:
        return size;
    }
  }

  useEffect(() => {
    if (bodyWidth && bodyHeight) {
      switch (placement) {
        case "top":
          setPlacementStyling({
            left: (-sizeWidth + bodyWidth) / 2,
            bottom: bodyHeight + 5,
          });
          break;
        case "left":
          setPlacementStyling({
            left: -sizeWidth - 5,
            top: -5,
          });
          break;
        case "right":
          setPlacementStyling({
            left: bodyWidth + 5,
            top: -5,
          });
          break;
        case "top-right":
          setPlacementStyling({
            left: 5,
            bottom: bodyHeight + 5,
          });
          break;
        case "bottom-left":
          setPlacementStyling({
            left: -sizeWidth,
            top: bodyHeight + 5,
          });
          break;
        default:
          setPlacementStyling({
            left: (-sizeWidth + bodyWidth) / 2,
            top: bodyHeight + 5,
          });
          break;
      }
    }
  }, [bodyWidth, bodyHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={customStyle}>
      <div
        ref={ref}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        className="relative cursor-pointer inline-block"
      >
        {children}

        {isHovering && (
          <div
            style={{ width: sizeWidth, ...placementStyling }}
            className={`absolute break-normal z-50 py-2 px-2 text-center text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-80`}
          >
            {text}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
