import React, {Fragment, useCallback, useEffect, useState} from "react";
import { useIntl } from 'react-intl';
import styled from "styled-components";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {useTimer} from "react-timer-hook";
import Button from "./Button";
import Container from "./Container";
import SlideBox, {SlideBackground} from "./SlideBox";
import AuthHeader from "../ui-components/layout/AuthHeader";
import ImageProgressBar from "./ImageProgressBar";
import Spinner from "../ui-components/Spinner";
import {useHistory} from 'react-router-dom';
import { ArrowSmallRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import PublicUserProfiling from "./PublicUserProfiling/PublicUserProfiling";
import MidGameSlide from "./PublicSurvey/MidGame/MidGameSlide";
import ResultsPrelude from "./PublicSurvey/ResultsPrelude/ResultsPrelude";
import StartGame from "./PublicSurvey/StartGame/StartGame";


const timerSeconds = 3;


const restartTimer = (restart, setStartTime) => {
  const time = new Date();


  setStartTime(time.getTime());
  time.setSeconds(time.getSeconds() + timerSeconds);
  restart(time);
};

const Slide = ({
  index,
  activeSlide,
  nextIndex,
  nextSlide,
  tutorial,
  images,
  title,
  description,
  adjective,
  id_adjective,
  last,
  logos,
  start,
  end,
  entry,
  loader,
  cta,
  next,
  subtitle,
  adjectiveDescription,
  roundsNumber,
  sessionId,
  isTutorial,
  slideType,
  userProfilingData,
  setUserProfilingData,
  firstSlideRerender,
  setFirstSlideRerender,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const [image, setImage] = useState(
    id_adjective
      ? {
        index: 1,
        ...images[0],
      }
      : null
  );

  const {seconds, restart} = useTimer({
    expiryTimestamp: new Date(),
  });

  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    if (activeSlide === index && !startTime && !nextIndex && !slideType) {
      restartTimer(restart, setStartTime);
    }
  }, [activeSlide, index, startTime, nextIndex, slideType, restart]);

  function backHome() {
    history.push("/home");
  }

  const onClick = useCallback(
    (response, save, nextImage) => {
      if (!tutorial && !start && !slideType) {
        const elapsed_time = new Date().getTime() - startTime;
        nextSlide(
          {val_elapsed_time: elapsed_time, flg_response: response, id_image: image?.id, id_adjective, uid_session: sessionId},
          null,
          nextImage
        );
        if (nextImage) {
          restartTimer(restart, setStartTime);
          setImage({index: image.index + 1, ...images[image.index]});
        }
      } else {
        if (nextImage) {
          restartTimer(restart, setStartTime);
          setImage({index: image.index + 1, ...images[image.index]});
          return;
        }
        nextSlide(null, save);
      }
    },
    [
      nextSlide,
      startTime,
      slideType,
      tutorial,
      id_adjective,
      restart,
      images,
      image,
      start,
      sessionId,
    ]
  );

  useEffect(() => {
    if (!entry && last) {
      onClick(null, true);
    }
    // eslint-disable-next-line
  }, []);

  if (loader) {
    return (
      <SlideBackground>
        <SlideBox>
          <Spinner/>
        </SlideBox>
      </SlideBackground>
    );
  }

  const _setCustomSlideId = () => {
    switch (slideType) {
      case "PublicMidGame":
        return "public-mid-game-slide";
      case "UserProfiling":
        return "public-user-profile-slide";
      case "ResultsPrelude":
        return "public-results-prelude-slide";
      case "StartGame":
        return "public-start-game-slide";
      default:
        return "";
    }
  }

  const _renderCustomComponent = () => {
    switch (slideType) {
      case "PublicMidGame":
        return (
          <MidGameSlide
            onClick={end ? () => backHome() : last ? () => onClick(null, true, null) : onClick}
          />
        )
      case "UserProfiling":
        return (
          <PublicUserProfiling
            userProfilingData={userProfilingData}
            setUserProfilingData={setUserProfilingData}
            onClick={onClick}
          />
        )
      case "ResultsPrelude":
        return (
          <ResultsPrelude 
            onClick={end ? () => backHome() : last ? () => onClick(null, true, null) : onClick}
          />
        )
      case "StartGame":
        return (
          <StartGame 
            rounds={roundsNumber}
            onClick={
              end ? () => backHome() : 
                last ? () => onClick(null, true, null) :
                () => {
                  setFirstSlideRerender(false);
                  onClick(null, null, null);
                }
            }
          />
        )
      default:
        return <></>
    }
  }

  const _renderCustomSlide = () => {
    return (
      <SlideBackground>
        <SlideBox id={_setCustomSlideId()}>
          {
            _renderCustomComponent()
          }
        </SlideBox>
      </SlideBackground>
    )  
  }

  return (
    <SlideBackground>
      <SlideBox
        entry={entry}
        index={index}
        hidden={index !== activeSlide && index !== nextIndex}
        close={activeSlide === index && nextIndex}
      >
        {isTutorial && 
          <div className="sm:flex sm:gap-x-5 sm:items-center sm:block absolute top-5 right-0 pt-4 pr-4">
              
            <div className="font-bold">
              {intl.formatMessage({id: "close_to_return_menu"})}            
            </div>

            <div className="bg-white text-gray-400">
              <Stage>
                <BounceBox>
                  <ArrowSmallRightIcon className="h-6 w-6" />
                </BounceBox> 
              </Stage>
            </div>

            <button
              type="button"
              className="bg-white rounded-3xl text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-am-500"
              onClick={() => backHome()}
            >
              <XMarkIcon className="h-8 w-8" aria-hidden="true"/>
            </button>
          </div>
        }
        <Container>
          <ContentBox>
            {
              slideType && firstSlideRerender ? 
                _renderCustomSlide() :
                image ? (
                  <Fragment>
                    <Image
                      image={`${process.env.PUBLIC_URL}/generated_faces/${image.file_name}`}
                    >
                      {!nextIndex && (
                        <ProgressBox>
                          <CircularProgressbar
                            text={`${seconds}`}
                            value={(100 * seconds) / timerSeconds}
                            strokeWidth={50}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              textSize: "40px",
                              pathColor: "rgba(0, 71, 92, 0.6)",
                              textColor: "white",
                              trailColor: "#eb6c6c",
                            })}
                          />
                        </ProgressBox>
                      )}
                    </Image>
                    <ImageProgressBar images={images} index={image.index}/>
                  </Fragment>
                ) : (
                  <Fragment>
                    {title && <Title subtitle={subtitle}>{title}</Title>}
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    {description && (
                      <Description isStart={start}>{description}</Description>
                    )}
                    {adjectiveDescription && (
                      <BoxCta>
                        <Description>{adjectiveDescription}</Description>
                      </BoxCta>
                    )}
                    {cta && (
                      <BoxCta>
                        <TitleCta>{cta.title}</TitleCta>
                        <Cta>{cta.description}</Cta>
                        <SlimButton up onClick={cta.buttonSlimOnClick}>
                          {cta.buttonSlim}
                        </SlimButton>
                      </BoxCta>
                    )}
                    {logos && (
                      <div className="mt-6">
                        <AuthHeader/>
                      </div>
                    )}
                  </Fragment>
            )}
          </ContentBox>

          {!image && !last ? null : <Adjective>{adjective}</Adjective>}

          <div style={{width: "100%", marginTop: "auto"}}>
            {!image && !last ? (
              <BoxButton>
                <Button
                  disabled={nextIndex}
                  info
                  onClick={end ? () => backHome() : last ? () => onClick(null, true) : onClick}
                >
                  {end ? "ESCI" : start ? "INIZIA" : next ? "AVANTI" : "CONTINUA"}
                </Button>
              </BoxButton>
            ) : (
              <BoxButton>
                <WrapperButton>
                  <AdjectiveButton
                    disabled={nextIndex}
                    onClick={() =>
                      onClick(false, null, images.length > image.index)
                    }
                  >
                    NO
                    <i className="fa fa-thumbs-down"/>
                  </AdjectiveButton>
                  <AdjectiveButton
                    disabled={nextIndex}
                    up
                    onClick={() =>
                      onClick(true, null, images.length > image.index)
                    }
                  >
                    SI
                    <i className="fa fa-thumbs-up"/>
                  </AdjectiveButton>
                </WrapperButton>
              </BoxButton>
            )}
          </div>
        </Container>
      </SlideBox>
    </SlideBackground>
  );
};

const Stage = styled.div`
  display: flex;
  width: 50px;
`

const BounceBox = styled.div`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  width: 200px;
  animation-name: bounce-6;
  animation-timing-function: ease;

  @keyframes bounce-6 {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(30px); }
    100% { transform: translateX(0); }
  }
`


const AdjectiveButton = styled(Button)`
  padding: ${20 / 16}em;
`;

const SlimButton = styled(Button)`
  padding: 4px 10px;
  margin-top: 7px;
  font-size: 0.8em;
`;

const ProgressBox = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 40px;
  height: 40px;
`;

const Adjective = styled.p`
  text-align: center;
  font-size: 1.8em;
  margin-top: 10px;
  font-weight: 700;
  color: #5e5e5e;
  text-transform: uppercase;
`;

const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  button {
    width: 48%;
  }
`;

const BoxButton = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.div`
  height: 60vh;
  background-color: #8080803d;
  width: 100%;
  position: relative;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 330px) {
    height: 45vh;
  }

  @media (max-width: 350px) {
    height: 50vh;
  }
`;

const Title = styled.p`
  font-size: 2em;
  margin-bottom: ${(props) => (props.subtitle ? "0" : "1em")};
  text-align: center;
`;

const Subtitle = styled(Title)`
  font-size: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
  text-align: center;
`;

const Description = styled.p`
  font-size: ${(props) => (props.isStart ? "2.2em" : "1.3em")};
  text-align: center;
  font-weight: ${(props) => (props.isStart ? "700" : "400")};
  text-transform: ${(props) => (props.isStart ? "uppercase" : "none")};
  line-height: 1.1;
  color: #5e5e5e;
`;

const BoxCta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const TitleCta = styled.p`
  font-size: 1.2em;
  text-transform: uppercase;
  text-align: center;
`;

const Cta = styled.p`
  font-size: 2em;
  text-align: center;
`;
const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default Slide;