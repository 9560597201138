import React from "react";
import styled from "styled-components";
import Container from "./Container";

const SlidesStep = ({ slides, activeSlide, isTutorial = false }) => {
  return (
    <StepsBox>
      <StepsContainer>
        <Box>
          {slides.map((s, index) => (
            index !== slides.length - 1 &&
            <Step
              key={s.id}
              stepsLength={!isTutorial ? slides.length : slides.length - 1}
              active={index + 1 === activeSlide}
            />
          ))}
        </Box>
      </StepsContainer>
    </StepsBox>
  );
};

const Step = styled.div`
  width: ${(props) => (102 - 2 * props.stepsLength) / props.stepsLength}%;
  margin: 0 1%;
  height: 3px;
  transition: background-color 0.3s linear;
  border-radius: 10px;
  background-color: ${(props) => (props.active ? "rgb(0, 71, 92)" : "#cfcfcf")};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const Box = styled.div`
  display: flex;
  width: 100%;
`;

const StepsContainer = styled(Container)`
  padding-top: 7px;
  padding-bottom: 0;
`;

const StepsBox = styled.div`
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
`;

export default SlidesStep;
