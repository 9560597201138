import { createContext } from 'react';
import { TypesDataContext } from './DataContext.types';

// data hooks
import useImagesPhenotypeFetch from '@hooks/useImages/useImagesPhenotypeFetch';
import useCountriesFetch from '@hooks/useCountries/useCountriesFetch';


const DataContext = createContext({});
const {Provider} = DataContext;


const DataProvider = ({children} : TypesDataContext) => {
  /* User Profiling  */
  const imagesPhenotype = useImagesPhenotypeFetch();
  const countries = useCountriesFetch();
  
  return (
    <Provider
      value={{
        imagesPhenotype,
        countries,
      }}
    >
      {children}
    </Provider>
  )
}

export {DataContext, DataProvider}