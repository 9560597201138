import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

// Model
import { PhenotypeScore, TypesPublicResultsAdjectiveSection } from '../TypesPublicResultsPage.types';
import { ResultsGenderScore } from '@hooks/useAggregatedResults/AggregatedResults.types';

//Components
import CoupleImagesPhenotype from './CoupleImagesPhenotype';


function PublicResultsAdjectiveSection({
  adjective,
  imagesGrouped,
  globalResults,
  userResults,
}: TypesPublicResultsAdjectiveSection) {
  const intl = useIntl();
  const [mostVotedGlobalPhenotype, setMostVotedGlobalPhenotype] = useState<PhenotypeScore>();
  const [leastVotedGlobalPhenotype, setLeastVotedGlobalPhenotype] = useState<PhenotypeScore>();
  const [mostVotedUserPhenotype, setMostVotedUserPhenotype] = useState<PhenotypeScore>();
  const [leastVotedUserPhenotype, setLeastVotedUserPhenotype] = useState<PhenotypeScore>();

  const _getMinMaxScores = useCallback((adjective: string) => {
      const globalAggregation: Record<string, ResultsGenderScore> = globalResults.filter(x => x.des_adjective === adjective)[0].jsn_aggregation;
      const userAggregation: Record<string, ResultsGenderScore> = userResults.filter(x => x.des_adjective === adjective)[0].jsn_aggregation;

      let globalMostPhenotype: PhenotypeScore = {phenotype: '', score: 0};
      let userMostPhenotype: PhenotypeScore = {phenotype: '', score: 0};
      let globalLeastPhenotype: PhenotypeScore = {phenotype: '', score: 100};
      let userLeastPhenotype: PhenotypeScore = {phenotype: '', score: 100};

      Object.keys(globalAggregation).forEach(phenotype => {
        if (globalAggregation[phenotype].ALL >= globalMostPhenotype.score) {
          globalMostPhenotype = {phenotype: phenotype, score: globalAggregation[phenotype].ALL};
        }
        if (globalAggregation[phenotype].ALL <= globalLeastPhenotype.score) {
          globalLeastPhenotype = {phenotype: phenotype, score: globalAggregation[phenotype].ALL};
        }
      });

      Object.keys(userAggregation).forEach(phenotype => {
        if (userAggregation[phenotype].ALL >= userMostPhenotype.score) {
          userMostPhenotype = {phenotype: phenotype, score: userAggregation[phenotype].ALL};
        }
        if (userAggregation[phenotype].ALL <= userLeastPhenotype.score) {
          userLeastPhenotype = {phenotype: phenotype, score: userAggregation[phenotype].ALL};
        }
      });

      setMostVotedGlobalPhenotype(globalMostPhenotype);
      setMostVotedUserPhenotype(userMostPhenotype);
      setLeastVotedGlobalPhenotype(globalLeastPhenotype);
      setLeastVotedUserPhenotype(userLeastPhenotype);
  }, [globalResults, userResults])

  useEffect(() => {
    _getMinMaxScores(adjective);
  }, [adjective, _getMinMaxScores])

  return (
    <div className='font-montserrat mt-5 flex flex-col items-center gap-y-5'>
      <div className='font-bold text-blue-600'>{adjective.toUpperCase()}</div>
      <div className='flex flex-col items-center gap-y-16 md:flex-row md:gap-x-8'>
        { mostVotedUserPhenotype && mostVotedGlobalPhenotype ?
            <div className='flex flex-col items-center gap-y-12 text-center' style={{ border: '3px solid green', backgroundColor: '#D0F0C0', borderRadius: '20px', padding: '5px' }}>
              <div className='flex flex-col items-center'>
                <div>{intl.formatMessage({ id: "group_most" })} {adjective} {intl.formatMessage({ id: "for_you" })} <strong>( {mostVotedUserPhenotype.score}% )</strong></div>
                <CoupleImagesPhenotype 
                  phenotypeLabel={mostVotedUserPhenotype.phenotype}
                  imagesArray={imagesGrouped[mostVotedUserPhenotype.phenotype]}
                />
              </div>

              <div className='flex flex-col items-center'>
                <div>{intl.formatMessage({ id: "users_voted_this" })} <strong>( {mostVotedGlobalPhenotype.score}% )</strong></div>
                <CoupleImagesPhenotype 
                  phenotypeLabel={mostVotedGlobalPhenotype.phenotype}
                  imagesArray={imagesGrouped[mostVotedGlobalPhenotype.phenotype]}
                />
              </div>
            </div> :
            null
        }
        { leastVotedUserPhenotype && leastVotedGlobalPhenotype ?
            <div className='flex flex-col items-center gap-y-12 text-center' style={{ border: '3px solid red', backgroundColor: '#F08080', borderRadius: '20px', padding: '5px' }}>
              <div className='flex flex-col items-center'>
                <div>{intl.formatMessage({ id: "group_least" })} {adjective} {intl.formatMessage({ id: "for_you" })} <strong>( {leastVotedUserPhenotype.score}% )</strong></div>
                <CoupleImagesPhenotype 
                  phenotypeLabel={leastVotedUserPhenotype.phenotype}
                  imagesArray={imagesGrouped[leastVotedUserPhenotype.phenotype]}
                />
              </div>

              <div className='flex flex-col items-center'>
                <div>{intl.formatMessage({ id: "users_voted_this" })} <strong>( {leastVotedGlobalPhenotype.score}% )</strong></div>
                <CoupleImagesPhenotype 
                  phenotypeLabel={leastVotedGlobalPhenotype.phenotype}
                  imagesArray={imagesGrouped[leastVotedGlobalPhenotype.phenotype]}
                />
              </div>
            </div> :
            null
        }
      </div>
    </div>
  );
}

export default PublicResultsAdjectiveSection;