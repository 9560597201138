import React from 'react';
import { useIntl } from 'react-intl';

// Components
import Alert from '@ui-components/Alert';
import Button from '@components/Button';
import { TypesStartGameSlide } from '../MidGame/MidGameSlide.types';


function StartGame({
  onClick,
  rounds,
}: TypesStartGameSlide) {
  const intl = useIntl();

  return (
    <div className='flex flex-col items-center gap-y-8' style={{ width: '90vw', overflow: 'auto' }}>
      <div className='text-2xl font-bold mt-8 text-center'>
        {intl.formatMessage({ id: "test_introduction" })}
        <strong>unconscious bias</strong>.
      </div>

      <div className='text-center'>
        {intl.formatMessage({ id: "test_introduction_two" })}
        <strong>{intl.formatMessage({ id: "test_introduction_three" })}</strong>
        {intl.formatMessage({ id: "test_introduction_four" })}
      </div>

      <div className='text-center'>
        {intl.formatMessage({ id: "test_introduction_five" })}
        <strong>{rounds} round: </strong>
        {intl.formatMessage({ id: "test_introduction_six" })}
        <strong>{intl.formatMessage({ id: "two_buttons" })}</strong>
        {intl.formatMessage({ id: "test_introduction_eight" })}
        <strong>tutorial </strong>
        {intl.formatMessage({ id: "test_introduction_nine" })}
      </div>

      <div className='font-bold text-center'>
        {intl.formatMessage({ id: "test_introduction_ten" })}
      </div>

      <Alert
        title={<b>{intl.formatMessage({id: "attention"})}</b>}
        text={<em>{intl.formatMessage({ id: "disclaimer_unconscious_bias" })}</em>}
        alertType='warning'
      />

      <div className='mb-8'>
        <Button
          // @ts-ignore
          info
          // @ts-ignore
          onClick={onClick}
        >
          {intl.formatMessage({ id: "start_caps_lock" })}
        </Button>
      </div>
    </div>
  );
}

export default StartGame;