import React, { useContext } from 'react';
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

// Components
import Button from '@ui-components/Button';
import Logo from '@components/Logo';

// Model & Data Services
import { getCurrentPublicSession, createPublicUser } from './StartVisualizationServices';
import { PublicGameSessionSchema, PublicGameUserSchema } from '@components/PublicSurvey/PublicSurvey.types';
import { NotificationsContext } from "@ui-components/Notifications";


function StartVisualization() {
  const intl = useIntl();
  const { push } = useContext(NotificationsContext);
  const history = useHistory();
  const URL = `${window.location.protocol}//${window.location.host}`;
  
  const startTest = async () => {
    // we need userSession store here?
    const session = await getCurrentPublicSession() as unknown as PublicGameSessionSchema;
    const publicUser = await createPublicUser(session) as unknown as PublicGameUserSchema;
    
    history.push(`/test/${session.uid_session}/${publicUser.uid_user}`)
  }

  return (
    <div>
      <div className="text-center mb-16 sm:text-end mt-6 sm:mr-8">
        <Button onClick={() => history.push("/login")}>{intl.formatMessage({ id: "teachers_area" })}</Button>
      </div>

      <div className='flex flex-col gap-y-3 items-center'>
        <div>
          <Logo height="h-40" imagePath={process.env.PUBLIC_URL + "/ops-screen.svg"} />
          <Logo height="h-32" imagePath={process.env.PUBLIC_URL + "/unicef-logo.svg"} />
        </div>
        <Logo height="h-12" imagePath={process.env.PUBLIC_URL + "/am-logo.svg"} />

        <div className='mt-5 w-48 h-20'>
          <Button full={true} onClick={() => startTest()}>{intl.formatMessage({ id: "start_test" })}</Button>
        </div>

        <div 
          className='font-bold text-unicef-200 cursor-pointer'
          onClick={() => {
            navigator.clipboard.writeText(URL);
            push({
              title: intl.formatMessage({id: 'link_copied'}),
              type: "success"
            });
          }}
        >
          {intl.formatMessage({ id: "invite_others_to_participate" })}
        </div>
      </div>
    </div>
  );
}

export default StartVisualization;