import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

// Model
import { TypesMidGameSlide } from './MidGameSlide.types';
import { GroupedImages } from '@components/PublicUserProfiling/PublicUserProfiling.types';

// Services
import { groupImagesByPhenotype } from '@components/PublicUserProfiling/PublicUserProfiling.services';
import { DataContext } from '@services/DataContext';

// Components
import Button from '@ui-components/Button';
import Alert from '@ui-components/Alert';
import CoupleImagesPhenotype from '@components/PublicResultsPage/PublicResultsPhenotype/CoupleImagesPhenotype';


function MidGameSlide({
  onClick,
}: TypesMidGameSlide) {
  const intl = useIntl();
  const dataContext: any = useContext(DataContext);
  const groupedImages: GroupedImages = groupImagesByPhenotype(dataContext.imagesPhenotype);
  
  return (
    <div className='flex flex-col items-center gap-y-8' style={{ width: '90vw', overflow: 'auto' }}>
      <div className='font-bold text-2xl mt-8 text-center'>
        {intl.formatMessage({ id: "thanks_for_partecipating" })}
      </div>

      <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-12 md:grid-cols-3'>
        {
          Object.keys(groupedImages).map(phenotype => {
            return (
              <CoupleImagesPhenotype
                key={phenotype}
                phenotypeLabel={phenotype}
                imagesArray={groupedImages[phenotype]} 
              />
            )
          })
        }
      </div>

      <Alert
        title={<b>{intl.formatMessage({id: "attention"})}</b>}
        text={<em>{intl.formatMessage({ id: "disclaimer_phenotype_explanation" })}</em>}
        alertType='warning'
      />

      <div className='mb-8'>
        <Button onClick={onClick}>
          {intl.formatMessage({ id: "continue" })}
        </Button>
      </div>
    </div>
  );
}

export default MidGameSlide;