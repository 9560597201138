import { IntlShape } from "react-intl";
import { AggregateResponseReturnSchema } from "@hooks/useAggregatedResults/AggregatedResults.types"
import { ResultsGenderScore } from "@hooks/useAggregatedResults/AggregatedResults.types";
import { GlobalPhenotypeByGenderResults } from "../TypesPublicResultsPage.types";


export const groupGlobalResultsByGender = (globalResults: AggregateResponseReturnSchema[]) => {
  return globalResults.map(result => {
    const phenotypeOptionsLength = Object.keys(result.jsn_aggregation).length;
    let sumF = 0;
    let sumM = 0;
    Object.keys(result.jsn_aggregation).forEach(phenotype => {
      sumF += result.jsn_aggregation[phenotype].F;
      sumM += result.jsn_aggregation[phenotype].M;
    });

    return {
      adjective: result.des_adjective.charAt(0).toLocaleUpperCase() + result.des_adjective.slice(1),
      F: parseFloat((sumF / phenotypeOptionsLength).toFixed(2)),
      M: parseFloat((sumM / phenotypeOptionsLength).toFixed(2)),
    };
  })
}

const _sortGlobalPhenotypeByGenderResults = (array: GlobalPhenotypeByGenderResults[]) => {
  return array.sort((a, b) => a.phenotype.localeCompare(b.phenotype));
} 

export const groupGlobalResultsByGenderAndPhenotype = (jsn_aggregation: Record<string, ResultsGenderScore>, intl?: IntlShape) => {
  return _sortGlobalPhenotypeByGenderResults(Object.keys(jsn_aggregation).map(phenotype => {
    return {
      phenotype: intl ? intl.formatMessage({ id: phenotype }) : phenotype,
      F: jsn_aggregation[phenotype].F,
      M: jsn_aggregation[phenotype].M
    };
  }))
}
