import React, { useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import AuthHeader from "../../layout/AuthHeader";
import PoweredBy from "../../layout/PoweredBy";
import AuthenticationService from "../../../services/AuthenticationService";
import Alert from "../../Alert";
import Button from "../../Button";
import Container from "../../Container";
import Input from "../../Input";
import UpdatePassword from "../../forms/UpdatePassword";
import { api } from "@services/apiRequest";

const Registration = () => {
  const intl = useIntl();
  const { token } = useParams();
  const [uid, setUid] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .post("/users/validate", { token: token }, { publicRequest: true })
      .then(({ data }) => {
        setUid(data.id);
        setEmail(data.email);
      })
      .catch((err) => {
        console.error("Error:", err);
        setError("Not authorized");
        setIsValid(false);
      })
      .finally(() => setLoading(false));
  }, [token]);

  const submit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!isValid) {
          return;
        }

        setLoading(true);
        setError("");
        const { data: response } = await api.post(
          "/users/signup",
          { uid: uid, password: password },
          { publicRequest: true }
        );

        if (response.errors) {
          setError(intl.formatMessage({ id: "form_error" }));
          return;
        }
        try {
          await AuthenticationService.login(email, password);
          window.location = "/home";
        } catch (error) {
          setError(intl.formatMessage({ id: "authentication_failed" }));
        }
      } catch (error) {
        setError(intl.formatMessage({ id: "error_while_saving" }));
      } finally {
        setLoading(false);
      }
    },
    [password, uid, email, intl, isValid]
  );

  return (
    <div className="bg-white min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Container>
        <AuthHeader title="Registrazione utente" />

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-unicef-400 py-8 px-4 shadow sm:rounded-3xl sm:px-10">
            <form action="#" method="POST" onSubmit={submit}>
              <div>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  label="Email"
                  type="email"
                  disabled
                />
              </div>

              <UpdatePassword
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                onValid={setIsValid}
              />

              <div>
                <Button
                  full
                  type="submit"
                  submitting={loading}
                  disabled={!isValid}
                >
                  <FormattedMessage id="signup" />
                </Button>
              </div>
              {error ? (
                <div className="mt-4">
                  <Alert slim alertType="error" title={error} />
                </div>
              ) : null}
            </form>
          </div>
          <PoweredBy />
        </div>
      </Container>
    </div>
  );
};

export default Registration;
