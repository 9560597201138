import { PublicGameSessionSchema } from "@components/PublicSurvey/PublicSurvey.types";
import apiRequest from "@services/apiRequest";

export async function getCurrentPublicSession() {
  try {
    return await apiRequest.get("/public_game_session/check", { publicRequest: true });
  } catch {
    // catch errors here
  }
}

export async function createPublicUser(
  session: PublicGameSessionSchema
) {
  try {
    return await apiRequest.get(`/public_game_session/${session.uid_session}/create_user`, { publicRequest: true });
  } catch {
    // catch errors here
  }
}