import React from 'react';
import { useIntl } from 'react-intl';

// Model
import { TypesPublicResultsGender } from '../TypesPublicResultsPage.types';

// Components
import PublicResultsGenderPhenotypeAdjective from './PublicResultsGenderPhenotypeAdjective';
import { PageSection } from '@ui-components/Container';

function PublicResultsGenderPhenotype({
  globalResults,
}: TypesPublicResultsGender) {
  const intl = useIntl();

  return (
    <div className='mt-10'>
      <PageSection resultsPage={true} title={intl.formatMessage({ id: "phenotype_adjective_gender_analysis" })}>
        <div className="flex flex-col gap-y-10 items-center">
          {
            globalResults.map(result => {
              return <PublicResultsGenderPhenotypeAdjective
                key={result.des_adjective}
                adjective={result.des_adjective}
                jsn_aggregation={result.jsn_aggregation}
              />
            })
          }
        </div>
      </PageSection>
    </div>
  );
}

export default PublicResultsGenderPhenotype;