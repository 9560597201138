import React from 'react';
import { useIntl } from 'react-intl';

// Components
import Alert from '@ui-components/Alert';
import Button from '@ui-components/Button';
import { TypesMidGameSlide } from '../MidGame/MidGameSlide.types';


function ResultsPrelude({
  onClick,
}: TypesMidGameSlide) {
  const intl = useIntl();

  return (
    <div className='flex flex-col items-center gap-y-8' style={{ width: '90vw', overflow: 'auto' }}>
      <div className='font-bold text-2xl mt-8 text-center'>
        {intl.formatMessage({ id: "ready_to_see_results" })}
      </div>

      <div className='flex flex-col gap-y-4 p-4 text-center'>
        <div>{intl.formatMessage({ id: "think_to_these_questions" })}</div>
        <div className='list-none flex flex-col gap-y-4'>
          <li style={{ border: "3px solid #52ACE4", borderRadius: "20px", padding: '10px'}}>{intl.formatMessage({ id: "phenotype_question" })}</li>
          <li style={{ border: "3px solid #52ACE4", borderRadius: "20px", padding: '10px'}}>{intl.formatMessage({ id: "unconscious_bias_question" })}</li>
        </div>
      </div>

      <Alert
        title={<b>{intl.formatMessage({id: "attention"})}</b>}
        text={<em>{intl.formatMessage({ id: "disclaimer_responses" })}</em>}
        alertType='warning'
      />

      <div className='mb-8'>
        <Button onClick={onClick}>
          {intl.formatMessage({ id: "go_to_results" })}
        </Button>
      </div>
    </div>
  );
}

export default ResultsPrelude;