import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from "styled-components";

// Model & Hooks
import { CountrySchema, PublicTimeCountryLivingOptionsEnum, PublicUserEducationDegreeOptionsEnum, PublicUserGenreOptionsEnum, PublicUserRegionOptionsEnum, TypesPublicUserProfiling } from './PublicUserProfiling.types';
import { checkUserProfilingDisabled, selectFromEnum, standardSelect } from './PublicUserProfiling.services';

// Components
import Button from '@ui-components/Button';
import PublicUserPhenotypeProfiling from './PublicUserPhenotypeProfiling';
import Placeholder from '@ui-components/Placeholder';

// Services
import { calculateYearOptionFromToday } from './PublicUserProfiling.services';
import { DataContext } from '@services/DataContext';


function PublicUserProfiling({
  onClick,
  userProfilingData,
  setUserProfilingData,
}: TypesPublicUserProfiling) {
  const intl = useIntl();
  const dataContext: any = useContext(DataContext);

  return (
    <div style={{ width: '90vw', overflow: 'auto' }}>
      <div className='font-bold text-2xl mt-8 text-center'>
        {intl.formatMessage({ id: "tell_something_about_you" })}
      </div>
      {
        dataContext.imagesPhenotype ? 
          <div className='mt-8'>
            <PublicUserPhenotypeProfiling 
              userProfilingData={userProfilingData}
              setUserProfilingData={setUserProfilingData}
              images={dataContext.imagesPhenotype}
            />
          </div> :
          <Placeholder
            height='h-10'
            width='w-32'
          />
      }
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-8 sm:gap-x-8 gap-y-4 sm:gap-y-2 ml-1'>
        <div className='max-w-xs w-full mx-auto'>
          {
            selectFromEnum({
              'intl': intl,
              'label': 'what_is_your_genre',
              'enumOptions': PublicUserGenreOptionsEnum,
              'value': userProfilingData?.des_gender,
              'onChange': (selectedValue) => setUserProfilingData({
                ...userProfilingData,
                des_gender: selectedValue.length ? selectedValue as PublicUserGenreOptionsEnum : undefined
              }),
              'translateOptions': true,
            })
          }
        </div>

        <div className='max-w-xs w-full mx-auto'>
          {
            standardSelect(
              intl,
              'what_is_your_age',
              userProfilingData?.val_year_birth?.toString(),
              calculateYearOptionFromToday(),
              (selectedValue: string | undefined) => setUserProfilingData({
                ...userProfilingData,
                val_year_birth: selectedValue ? parseInt(selectedValue) : undefined,
              }),
              false
            )
          }
        </div>

        <div className='max-w-xs w-full mx-auto'>
          {
            dataContext.countries ?
              standardSelect(
                intl,
                'where_are_you_born',
                userProfilingData?.id_country_birth ? dataContext.countries.filter((x: CountrySchema) => x.id === userProfilingData.id_country_birth)[0].des_name : undefined,
                dataContext.countries.map((el: CountrySchema) => el.des_name),
                (selectedValue: string | undefined) => setUserProfilingData({
                  ...userProfilingData,
                  id_country_birth: selectedValue ? dataContext.countries.filter((x: CountrySchema) => x.des_name === selectedValue)[0].id : undefined,
                }),
                false
              ) :
              <Placeholder 
                height='h-10'
                width='w-full'
              />
          }
        </div>

        <div className='max-w-xs w-full mx-auto'>
          {
            dataContext.countries ?
              standardSelect(
                intl,
                'where_are_you_living_now',
                userProfilingData?.id_country_living ? dataContext.countries.filter((x: CountrySchema) => x.id === userProfilingData.id_country_living)[0].des_name : undefined,
                dataContext.countries.map((el: CountrySchema) => el.des_name),
                (selectedValue: string | undefined) => setUserProfilingData({
                  ...userProfilingData,
                  id_country_living: selectedValue ? dataContext.countries.filter((x: CountrySchema) => x.des_name === selectedValue)[0].id : undefined,
                }),
                false
              ) :
              <Placeholder 
                height='h-10'
                width='w-full'
              /> 
          }
        </div>

        { 
          dataContext.countries && userProfilingData?.id_country_birth && userProfilingData.id_country_living && 
          userProfilingData.id_country_birth !== userProfilingData.id_country_living ?
            <div className='max-w-xs w-full mx-auto'>
              {
                selectFromEnum({
                  'intl': intl,
                  'label': 'since_from_you_live_in',
                  'enumOptions': PublicTimeCountryLivingOptionsEnum,
                  'value': userProfilingData?.des_time_country_living,
                  'onChange': (selectedValue) => setUserProfilingData({
                    ...userProfilingData,
                    des_time_country_living: selectedValue.length ? selectedValue as PublicTimeCountryLivingOptionsEnum : undefined
                  }),
                  translateOptions: true,
                  attachToLabel: dataContext.countries.filter((x: CountrySchema) => x.id === userProfilingData.id_country_living)[0].des_name + '?'
                })
              }
            </div> :
            null
        }
        
        {
          userProfilingData?.id_country_living && dataContext.countries && 
          ["Italia", "Italy"].includes(dataContext.countries.filter((x: CountrySchema) => x.id === userProfilingData.id_country_living)[0].des_name) ?
            <div className='max-w-xs w-full mx-auto'>
              {
                selectFromEnum({
                  'intl': intl,
                  'label': 'what_is_your_region',
                  'enumOptions': PublicUserRegionOptionsEnum,
                  'value': userProfilingData?.des_region,
                  'onChange': (selectedValue) => setUserProfilingData({
                    ...userProfilingData,
                    des_region: selectedValue.length ? selectedValue as PublicUserRegionOptionsEnum : undefined
                  }),
                  'translateOptions': false,
                })
              }
            </div> :
            null
        }

        <div className='max-w-xs w-full mx-auto'>
          {
            selectFromEnum({
              'intl': intl,
              'label': 'des_education_degree',
              'enumOptions': PublicUserEducationDegreeOptionsEnum,
              'value': userProfilingData?.des_education_degree,
              'onChange': (selectedValue) => setUserProfilingData({
                ...userProfilingData,
                des_education_degree: selectedValue.length ? selectedValue as PublicUserEducationDegreeOptionsEnum : undefined
              }),
              'translateOptions': true,
              'tooltipDescription': "insert_major_grade",
            })
          }
        </div>
      </div>
      <div className='mt-4'>
        <BoxButton>
          <Button
            onClick={onClick}
            disabled={checkUserProfilingDisabled(dataContext.countries, userProfilingData)}
          >
            {intl.formatMessage({ id: "proceed" })}
          </Button>
        </BoxButton>
      </div>
    </div>
  );
}

export default PublicUserProfiling;

const BoxButton = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
`;
