import { useEffect, useState } from "react";

import { TestImageSchema } from "./Images.types";
import { api } from "@services/apiRequest";


function useImagesPhenotypeFetch() {
  const [images, setImages] = useState<TestImageSchema[]>();
  
  useEffect(() => {
    async function fetchImages() {
      const {data: images} = await api.get<TestImageSchema[]>("images/phenotype", { publicRequest: true });
      setImages(images);
    }

    if (!images) {
      fetchImages();
    }
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  return images;
}

export default useImagesPhenotypeFetch;