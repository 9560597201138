import { useEffect, useState } from "react";

import { CountrySchema } from "./Countries.types";
import { api } from "@services/apiRequest";



const _moveItalyFirst = (countries: CountrySchema[]) => {
  const indexWithItaly = countries.findIndex((country) => country.des_name === "Italy" || country.des_name === "Italia");

  if (indexWithItaly > -1) {
    const italyOption = countries.splice(indexWithItaly, 1)[0];
    countries.unshift(italyOption);
  }
  
  return countries;
}


function useCountriesFetch() {
  const [countries, setCountries] = useState<CountrySchema[]>();
  
  useEffect(() => {
    async function fetchCountries() {
      // FIXME: language should be set by the user at the start of the test. At the moment, is hardcoded to italian
      const { data: countries } = await api.get<CountrySchema[]>("countries/?language=it-IT", { publicRequest: true });
      setCountries(_moveItalyFirst(countries));
    }

    if (!countries) {
      fetchCountries();
    }
  }, [countries]);
  
  return countries;
}

export default useCountriesFetch;