import React from "react";
import styled from "styled-components";

const ImageProgressBar = ({ images, index }) => {
  return (
    <Wrapper>
      <Progress width={`${(index / images.length) * 100}%`} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  height: 10px;
  background-color: #8080803d;
  margin-top: 15px;
`;

const Progress = styled.div`
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(0, 71, 92);
  transition: width 0.4s ease-out;
  width: ${(props) => props.width};
`;

export default ImageProgressBar;
