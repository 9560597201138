import { 
  PublicUserGenderOptions,
  PublicUserEducationDegreeOptions,
  PublicUserPhenotypeOptions,
  PublicUserRegionOptions,
  PublicTimeCountryLivingOptions,
} from "@components/PublicSurvey/PublicSurvey.types"
import { TestImageSchema } from "@hooks/useImages/Images.types"

export interface TypesPublicUserProfiling {
  userProfilingData: PublicUserProfilingData | undefined,
  setUserProfilingData: React.Dispatch<React.SetStateAction<PublicUserProfilingData | undefined>>,
  onClick: Function,
}

export interface TypesPublicUserPhenotypeProfiling {
  userProfilingData: PublicUserProfilingData | undefined,
  setUserProfilingData: React.Dispatch<React.SetStateAction<PublicUserProfilingData | undefined>>,
  images: TestImageSchema[],
}

export interface PublicUserProfilingData {
  des_gender?: PublicUserGenderOptions,
  id_country_birth?: number | undefined,
  id_country_living?: number | undefined,
  des_time_country_living?: PublicTimeCountryLivingOptions,
  des_region?: PublicUserRegionOptions,
  val_year_birth?: number | undefined,
  des_education_degree?: PublicUserEducationDegreeOptions,
  des_phenotype?: PublicUserPhenotypeOptions,
}

export enum PublicUserGenreOptionsEnum {
  M = "M",
  F = "F",
  Other = "Other",
  NoResponse = "NoResponse",
}

export enum PublicTimeCountryLivingOptionsEnum {
  LessThanYear = "LessThanYear",
  LessThanThreeYears = "LessThanThreeYears",
  LessThanFiveYears = "LessThanFiveYears",
  LessThanTenYears = "LessThanTenYears",
  MoreThanTenYears = "MoreThanTenYears",
}

export enum PublicUserEducationDegreeOptionsEnum {
  Elementary = "Elementary",
  MiddleSchool = "MiddleSchool",
  Diploma = "Diploma",
  BachelorDegree = "BachelorDegree",
  MasterDegree = "MasterDegree",
  SingleCycleMasterDegree = "SingleCycleMasterDegree",
  MasterOrDoctoralDegree = "MasterOrDoctoralDegree",
  Other = "Other",
}

export enum PublicUserPhenotypeOptionsEnum {
  EastAsian = "EastAsian",
  SouthAsian = "SouthAsian",
  SubSaharan = "SubSaharan",
  Caucasian = "Caucasian",
  LatinAmerican = "LatinAmerican",
  Arabian = "Arabian",
}

export enum PublicUserRegionOptionsEnum {
  Abruzzo = "Abruzzo",
  Basilicata = "Basilicata",
  Calabria = "Calabria",
  Campania = "Campania",
  EmiliaRomagna = "Emilia Romagna",
  FriuliVeneziaGiulia = "Friuli Venezia Giulia",
  Lazio = "Lazio",
  Liguria = "Liguria",
  Lombardia = "Lombardia",
  Marche = "Marche",
  Molise = "Molise",
  Piemonte = "Piemonte",
  Puglia = "Puglia",
  Sardegna = "Sardegna",
  Sicilia = "Sicilia",
  Toscana = "Toscana",
  TrentinoAltoAdige = "Trentino Alto Adige",
  Umbria = "Umbria",
  ValDAosta = "Val d'Aosta",
  Veneto = "Veneto",
}

export type GroupedImages = Record<string, TestImageSchema[]>;
export interface TypesImageProps {
  image: string,
}

export interface CountrySchema {
  id: number;
  des_name: string;
}
