import "./App.css";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./ui-components/PrivateRoute";
import Dashboard from "./ui-components/Dashboard";
import Login from "./ui-components/pages/Login";
import Survey from "./components/Survey/Survey";
import Results from "./components/Survey/Results";
import OAuth2 from "./ui-components/pages/OAuth2";
import Registration from "./ui-components/pages/Registration";
import Notifications from "./ui-components/Notifications";
import menu from "./ui-components/AppNavigation/menu";
import Logout from "./ui-components/pages/Logout";
import NotFound from "./ui-components/pages/NotFound";
import ErrorBoundary from "./ui-components/ErrorBoundary";
import locale from "./locale";
import publicRoutes from "./routes/public";
import usePageViews from "@hooks/usePageViews";

import { NavigationProvider } from "@services/NavigationContext";
import { ApiAuthProvider } from "@services/ApiRequestContext";

import StartVisualization from "@pages/StartVisualization";
import PublicSurvey from "@components/PublicSurvey/PublicSurvey";
import { DataProvider } from "@services/DataContext";
import PublicEndTest from "@components/PublicEndTest/PublicEndTest";

function App() {
  usePageViews();

  return (
    <NavigationProvider>
      <ErrorBoundary>
        <IntlProvider
          messages={locale[localStorage.getItem("lang")]}
          locale={localStorage.getItem("lang")}
          defaultLocale="it"
        >
          <Notifications>
            <DataProvider>
              <ApiAuthProvider>
                <Switch>
                  <Route path="/survey/:session_uuid" component={Survey} />
                  <Route path="/test/:session_uuid/:user_uuid" component={PublicSurvey} />
                  <Route path="/results/:session_uuid/:user_uuid" component={Results} />
                  <Route path="/endTest/explanation" component={PublicEndTest} />
                    <PrivateRoute
                      exact
                      path="/"
                      component={() => <Redirect to={{ pathname: "/home" }}/>}
                    />
                    <Route path="/test" component={StartVisualization} />
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/oauth2" component={OAuth2}/>
                    <Route exact path="/registration/:token" component={Registration}/>

                    {publicRoutes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.href}
                        component={route.component}
                      />
                    ))}

                <PrivateRoute
                  path={[
                    ...menu
                      .reduce(
                        (acc, route) => [
                          ...acc,
                          ...(route.dropdown ? route.dropdown : [route]),
                        ],
                        []
                      )
                      .map((route) => route.href),
                    "/profile",
                  ]}
                  component={Dashboard}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </ApiAuthProvider>
            </DataProvider>
          </Notifications>
        </IntlProvider>
      </ErrorBoundary>
    </NavigationProvider>
  );
}

export default App;
