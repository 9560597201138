const descriptions = {
  attiv:
    "Di persona che, per natura, non rifugge dalla fatica, dallo sforzo, dall’impegno fisico o intellettuale, e dall’azione in genere, o che agisce e opera con velocità ed entusiasmo.",
  bell:
    "Che produce un’impressione estetica gradevole",
  simpatic:
    "Che desta un sentimento di interesse o gradimento.",
  onest:
    "Che desta un sentimento di lealtà e correttezza",
  ricc:
    "Che possiede denari, beni, sostanze e in genere mezzi di sussistenza in misura maggiore di quanto occorra per vivere in modo normale (si oppone a pover*).",
  intelligent:
    "Di persona che capisce e apprende con facilità, che vede e giudica le cose con chiarezza",
  altruist:
    "Chi ricerca, come fine del proprio operare, il bene altrui.",
  migrant: "Che migra, che si sposta verso nuove sedi.",
};

export default descriptions;
