import apiRequest from "@services/apiRequest";

export async function checkTestUrl(
  session_uuid: string,
  user_uuid: string
) {
  try {
    const validationUrlResponse = await apiRequest.post("/public_game_session/check_url", {
      uid_session: session_uuid,
      uid_user: user_uuid
    }, {
      publicRequest: true
    });
    return validationUrlResponse;
  } catch {
    // catch errors here: return accessDenied = true because an error (es. code 422) occurred
    return true;
  }
}