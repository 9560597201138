import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

// Model
import { TypesPublicResultsGenderPhenotypeAdjective } from '../TypesPublicResultsPage.types';
import { GlobalPhenotypeByGenderResults } from '../TypesPublicResultsPage.types';

// Services
import { groupGlobalResultsByGenderAndPhenotype } from './PublicResultsGender.services';

// Components
import VerticalBarChart from '@components/Charts/VerticalBarChart';


function PublicResultsGenderPhenotypeAdjective({
  adjective,
  jsn_aggregation,
}: TypesPublicResultsGenderPhenotypeAdjective) {
  const intl = useIntl();
  const [resultsAggregated, setResultsAggregated] = useState<GlobalPhenotypeByGenderResults[]>();

  useEffect(() => {
    if (!resultsAggregated) {
      setResultsAggregated(groupGlobalResultsByGenderAndPhenotype(jsn_aggregation, intl));
    }
  }, [resultsAggregated, jsn_aggregation, intl]);

  return (
    <div className='font-montserrat flex flex-col items-center'>
      <div className='uppercase text-blue-600 font-bold'>{adjective}</div>
      {
        resultsAggregated ?
          <VerticalBarChart 
            id={`${adjective}-gender-aggregation`}
            data={resultsAggregated}
            categoryField="phenotype"
            percentage={true}
            rotateLabel={true}
          /> : null
      }
    </div>
  );
}

export default PublicResultsGenderPhenotypeAdjective;