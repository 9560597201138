import React from 'react';
import {
  HomeIcon,
  ClockIcon,
  AcademicCapIcon,
  LightBulbIcon
} from "@heroicons/react/24/outline";
const Home = React.lazy(() =>
  import(/* webpackChunkName: "home" */ "@pages/Home")
);
const Gamesessions = React.lazy(() =>
  import(/* webpackChunkName: "gameSessions" */ "@pages/Gamesessions")
);
const Tutorial = React.lazy(() =>
  import(/* webpackChunkName: "tutorial" */ "@pages/Tutorial")
);
const Guide = React.lazy(() =>
  import(/* webpackChunkName: "guide" */ "@pages/Guide")
);

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home
  },
  {
    name: "sessions",
    href: "/sessions",
    Icon: ClockIcon,
    component: Gamesessions
  },
  {
    name: "guide",
    href: "/guide",
    Icon: AcademicCapIcon,
    component: Guide
  },
  {
    name: "tutorial",
    href: "/tutorial",
    Icon: LightBulbIcon,
    component: Tutorial
  }
];

export default routes;
