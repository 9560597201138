import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// Model
import { CompleteTestSchema, PublicSurveyParams, TestSingleResponseSchema, TestSlideSchema, ValidationUrlResponseSchema } from './PublicSurvey.types';
import { TestImageSchema } from '@hooks/useImages/Images.types';

// Services
import { checkTestUrl } from './PublicSurvey.services';
import UserSessionStore from '@services/userSessionStore';

// Components
import NotFound from '@ui-components/pages/NotFound';
import Slides from '@components/Survey/Slides';
import PublicResultsPage from '@components/PublicResultsPage/PublicResultsPage';


function PublicSurvey() {
  const { session_uuid, user_uuid } = useParams<PublicSurveyParams>();
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [completeTestState, setCompleteTestState] = useState<CompleteTestSchema>();
  const [userSessionStore, setUserSessionStore] = useState<UserSessionStore>();

  useMemo(() => {
    setUserSessionStore(new UserSessionStore());
  }, [])

  useEffect(() => {
    async function checkUrlValidity(session_uuid: string, user_uuid: string) {
      const validationUrlResponse = await checkTestUrl(session_uuid, user_uuid) as unknown as ValidationUrlResponseSchema;
      setAccessDenied(validationUrlResponse.denied);

      if (validationUrlResponse.public_user && userSessionStore) {
        userSessionStore.setUserData(
          session_uuid, 
          {
            'uid_user': validationUrlResponse.public_user.uid_user,
            'cod_user': validationUrlResponse.public_user.cod_user,
          }
        );
      }
    }

    if (session_uuid && user_uuid) {
      checkUrlValidity(session_uuid, user_uuid);
    }
  }, [session_uuid, user_uuid, userSessionStore]);

  if (accessDenied) {
    return (
      <NotFound />
    )
  }

  if (completeTestState) {
    return (
      <PublicResultsPage
        uid_user={user_uuid}
      />
    );
  }

  return (
    <>
      {
        session_uuid && !accessDenied ?
        <Slides 
          isTutorial={false}
          isPublicTest={true}
          sessionId={session_uuid}
          onComplete={(
            user_uuid: string,
            user_code: string,
            result: TestSingleResponseSchema[],
            slides: TestSlideSchema[],
            images: TestImageSchema[],
          ) => {
            setCompleteTestState({
              'user_uuid': user_uuid,
              'user_code': user_code,
              'result': result,
              'slides': slides,
              'images': images
            })
          }}
        /> :
        null
      }
    </>
  );
}

export default PublicSurvey;