import styled, { keyframes } from "styled-components";

const fadeInUp = keyframes`
  0% {
    transform:translateY(70%);
  }
  100% {
    transform:translateY(0);
  }
`;
const fadeOutUp = keyframes`
  0% {
    transform:translateY(0);
  }
  100% {
    transform:translateY(-150px);
  }
`;

// it isn't styled, but it works
// SlideBackground hides application pages when tutorial is shown
export const SlideBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  height: 100%;
  width: 100%;
`

const SlideBox = styled.div`
  position: fixed;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  top: 0;
  left: 0;
  z-index: ${(props) => props.index * 10 || 10};
  transition: opacity 0.2s linear;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${(props) =>
    props.index % 2 === 0 || !props.index ? "white" : "#fdfdfd"};
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: ${(props) => (props.close ? 0.6 : 1)};
  animation: ${(props) =>
      props.close ? fadeOutUp : props.entry ? fadeInUp : null}
    ${(props) => (props.close ? "0.6s" : "0.5s")}
    cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
`;

export default SlideBox;
