import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import styled from "styled-components";

// Components
import Logo from '@components/Logo';
import Alert from '@ui-components/Alert';
import { HomeIcon, ArrowSmallRightIcon } from "@heroicons/react/24/outline";


function PublicEndTest() {
  const intl = useIntl();
  const history = useHistory();

  return (
    <div className='flex flex-col items-center gap-y-8 mx-auto text-lg'  style={{ width: '90vw', overflow: 'auto' }}>
      <div className="flex gap-x-5 items-center block md:absolute md:top-5 md:right-0 pt-4 md:pr-4">

        <div className="font-bold text-sm w-20">
          {intl.formatMessage({id: "return_home"})}            
        </div>

        <div className="bg-white text-gray-400">
          <Stage>
            <BounceBox>
              <ArrowSmallRightIcon className="h-6 w-6" />
            </BounceBox> 
          </Stage>
        </div>

        <button
          type="button"
          className="bg-white rounded-3xl text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-am-500"
          onClick={() => history.push("/test")}
        >
          <HomeIcon className="h-8 w-8" aria-hidden="true"/>
        </button>
      </div>
  
      <div className='flex gap-x-4'>
        <Logo height="h-40" imagePath={process.env.PUBLIC_URL + "/ops-screen.svg"} />
        <Logo height="h-32" imagePath={process.env.PUBLIC_URL + "/unicef-logo.svg"} />
      </div>

      <div>
        {intl.formatMessage({ id: "endTest_first_paragraph" })}
      </div>

      <div>
        <div className='font-bold'>OPS! Web app</div>
        {intl.formatMessage({ id: "endTest_second_paragraph" })}
      </div>
      
      <div className='flex flex-col gap-y-4 bg-unicef-300 text-white p-4' style={{ borderRadius: '20px'}}>
        <div>{intl.formatMessage({ id: "endTest_third_paragraph" })} <em className='font-bold'>"{intl.formatMessage({ id: "migrant" })}"</em> :</div>
        <div className='list-none'>
          <li style={{ borderLeft: "3px solid white", padding: '10px 0px 10px 10px'}}>{intl.formatMessage({ id: "did_you_ever_think" })} <em>"{intl.formatMessage({ id: "migrant" })}"</em> {intl.formatMessage({ id: "endTest_first_point" })}</li>
          <li style={{ borderLeft: "3px solid white", padding: '10px 0px 10px 10px'}}>{intl.formatMessage({ id: "endTest_second_point" })}</li>
          <li style={{ borderLeft: "3px solid white", padding: '10px 0px 10px 10px'}}>{intl.formatMessage({ id: "endTest_third_point" })}</li>
        </div>
      </div>

      <div>
        {intl.formatMessage({ id: "endTest_fourth_paragraph" })}
        <a className='text-unicef-200' target="_blank" rel="noreferrer" href='https://www.unicef.it/minori-migranti-rifugiati/inclusione-partecipazione/campagna-ops/'> “OPS! – la tua Opinione, oltre ogni Pregiudizio, contro gli Stereotipi!”</a> {intl.formatMessage({ id: "be_part_of_change" })}
      </div>

      <div className='mb-8'>
        <Alert
          title={<b className='font-bold'>{intl.formatMessage({ id: "how_can_i_help" })}</b>}
          text={
            <div>
              <em>{intl.formatMessage({ id: "endTest_fifth_paragraph" })}.</em>
              <p className='italic'>{intl.formatMessage({ id: "click" })} <a style={{color: 'red'}} target="_blank" rel="noreferrer" href='https://www.unar.it/portale/web/guest/contact-center'>{intl.formatMessage({ id: "here" })}</a> {intl.formatMessage({ id: "how_to_do_it" })}!</p>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default PublicEndTest;


const Stage = styled.div`
  display: flex;
  width: 50px;
`

const BounceBox = styled.div`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  width: 200px;
  animation-name: bounce-6;
  animation-timing-function: ease;

  @keyframes bounce-6 {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(30px); }
    100% { transform: translateX(0); }
  }
`