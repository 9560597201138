import React, {useEffect, useState} from "react";
import CompletePage from "../CompletePage";

import {useParams} from 'react-router-dom';

import Slides from "./Slides";

import NotFound from "../../ui-components/pages/NotFound";
import apiRequest from "../../services/apiRequest";
import UserSessionStore from "../../services/userSessionStore";

const Survey = () => {
  const {session_uuid} = useParams();
  const [accessDenied, setAccessDenied] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [completeState, setCompleteState] = useState();
  const userSessionstore = new UserSessionStore();

  useEffect(() => {
    async function fetchInfo() {
      try {
        const session = await apiRequest.get(`/gamesession/${session_uuid}`, {
          publicRequest: true,
        })
        const canProceed = session.tms_started_at && !session.tms_stopped_at && !userSessionstore.getCompleted(session.id);
        // permits access to user if session started but is not stopped
        setAccessDenied(!canProceed);

        if (!canProceed) {
          return
        }
        const previousData = userSessionstore.getUserData(session.id);
        if (previousData === undefined || !previousData['uid']) {
          const user = await apiRequest.post(`/gamesession/${session_uuid}/createuser`, {}, {
            publicRequest: true,
            audit: false
          });

          setAccessDenied(!user);
          userSessionstore.setUserData(session.uid_session, user);
        }

        setSessionId(session.uid_session);
      } catch (e) {
        console.error(e)
        setAccessDenied(true);
      }
    }

    fetchInfo()
  }, [session_uuid]); // eslint-disable-line react-hooks/exhaustive-deps


  if (accessDenied) {
    return (
      <NotFound/>
    )
  }

  if (completeState) {
    return (
      <CompletePage
        session_uuid={session_uuid}
        {...completeState}
      />
    );
  }

  return (
    <>
      {sessionId &&
        <Slides
          isTutorial={false}
          sessionId={sessionId}
          onComplete={(user_uuid, user_code, result, slides, images) => {
            setCompleteState({
              user_uuid,
              user_code,
              result,
              slides,
              images
            })
          }}
        />
      }
    </>
  );
};

export default Survey;
