import React from 'react';

import { TypesCoupleImagesPhenotype } from '../TypesPublicResultsPage.types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TypesImageProps } from '@components/PublicUserProfiling/PublicUserProfiling.types';


function CoupleImagesPhenotype({
  phenotypeLabel,
  imagesArray,
}: TypesCoupleImagesPhenotype) {
  const intl = useIntl();

  return (
    <div className='flex flex-col items-center'>
      <div className='font-bold'>{intl.formatMessage({ id: phenotypeLabel })}</div>
      <div className='flex gap-x-3'>
        {
          imagesArray.map(image => {
            return <PhenotypeImage 
              key={image.file_name}
              image={`${process.env.PUBLIC_URL}/generated_faces/${image.file_name}`}
            />
          })
        }
      </div>
    </div>
  );
}

const PhenotypeImage = styled.div`
  height: ${window.innerWidth < 640 ? '75px' : '100px'};
  background-color: #8080803d;
  width: ${window.innerWidth < 640 ? '75px' : '100px'};
  position: relative;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-image: url(${(props: TypesImageProps) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export default CoupleImagesPhenotype;