import React, { useEffect, useState } from 'react';

// Model
import { TypesPublicResultsPhenotypeSection } from '../TypesPublicResultsPage.types';

// Components
import PublicResultsAdjectiveSection from './PublicResultsAdjectiveSection';
import Tabs from '@ui-components/Tabs';


function PublicResultsPhenotypeSection({
  globalResults,
  userResults,
  imagesGrouped,
}: TypesPublicResultsPhenotypeSection) {
  const [tabOptions, setTabOptions] = useState<string[]>();
  const [activeTab, setActiveTab] = useState<number>();

  useEffect(() => {
    if (!tabOptions) {
      setTabOptions(globalResults.map(adjective => adjective.des_adjective));
      setActiveTab(0);
    }
  }, [tabOptions, globalResults]);

  return (
    <div className='p-4 sm:p-0 flex flex-col items-center'>
      {
        tabOptions ?
          <Tabs
            tabs={tabOptions.map(tabName => ({name: tabName.charAt(0).toUpperCase() + tabName.slice(1)}))}
            active={activeTab}
            setActiveTab={setActiveTab}
          /> :
          null
      }
      {
        activeTab !== undefined ?
          <PublicResultsAdjectiveSection
            adjective={globalResults[activeTab].des_adjective}
            globalResults={globalResults}
            userResults={userResults}
            imagesGrouped={imagesGrouped}
          /> :
          null
      }
    </div>
  );
}

export default PublicResultsPhenotypeSection;