import axios from "axios";
import { API_URL } from "../../utils/constants";
import AuthenticationService from "../AuthenticationService";

export default class ApiService {
  static getHeaders() {
    const userData = AuthenticationService.getUserData();
    return { Authorization: "Bearer " + userData.access_token };
  }

  static async getApi(typeObject, args) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = args === undefined ? baseUrl : baseUrl + `${args}`;
    return await axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async postApiJson(typeObject, data) {
    const jsonData = JSON.stringify(data);
    let requestHeaders = {};
    requestHeaders["Content-Type"] = "application/json";
    return await axios
      .post(`${API_URL}/${typeObject}/`, jsonData, { headers: requestHeaders })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async publicPostApi(typeObject, data) {
    const jsonData = JSON.stringify(data);
    return await axios
      .post(`${API_URL}/${typeObject}`, jsonData)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async deleteApi(typeObject) {
    return await axios
      .delete(`${API_URL}/${typeObject}/`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}
