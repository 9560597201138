import { useState, useEffect } from "react";
import apiRequest from "../../services/apiRequest";

const useUserCode = (userUUID) => {
  const [code, setCode] = useState();
  useEffect(() => {
    apiRequest.get(`gamesession/user/${userUUID}/alias`, {
      publicRequest: true,
    })
      .then((result) => {
        setCode(result);
      });
  }, [userUUID]);
  return code;
};

export default useUserCode;