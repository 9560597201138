import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

// Model
import { GlobalAdjectiveByGenderResults, TypesPublicResultsGender } from '../TypesPublicResultsPage.types';

// Services
import { groupGlobalResultsByGender } from './PublicResultsGender.services';

// Components
import VerticalBarChart from '@components/Charts/VerticalBarChart';
import Spinner from '@ui-components/Spinner';
import PublicResultsGenderPhenotype from './PublicResultsGenderPhenotype';
import Tooltip from '@ui-components/Tooltip';
import { PageSection } from '@ui-components/Container';


function PublicResultsGender({
  globalResults,
}: TypesPublicResultsGender) {
  const intl = useIntl();
  const [adjectiveResultsGroupedByGender, setAdjectiveResultsGroupedByGender] = useState<GlobalAdjectiveByGenderResults[]>();

  useEffect(() => {
    if (!adjectiveResultsGroupedByGender) {
      setAdjectiveResultsGroupedByGender(groupGlobalResultsByGender(globalResults));
    }
  }, [adjectiveResultsGroupedByGender, globalResults]);

  return (
    <div>
      {
        adjectiveResultsGroupedByGender ?
          <div className='mt-4 flex flex-col items-center gap-y-2'>
            <PageSection resultsPage={true} title={intl.formatMessage({ id: "gender_analysis" })}> 
              <div className='flex flex-col items-center'>
                <div className='flex flex-row gap-x-2'> 
                  <div className='font-montserrat font-bold'>{intl.formatMessage({ id: "global_results" })}</div>
                  <Tooltip 
                    text={intl.formatMessage({ id: 'results_explanation' })}
                    size={200}
                    placement='bottom-left'                 
                  />
                </div>
                <VerticalBarChart
                  id="gender-global-chart"
                  data={adjectiveResultsGroupedByGender}
                  categoryField="adjective"
                  percentage={true}
                />
              </div>
            </PageSection>

            <PublicResultsGenderPhenotype 
              globalResults={globalResults} 
            />
          </div> :
          <Spinner 
            styleType='loader'
            description={intl.formatMessage({ id: "loading_results" })}
          />
      }
    </div>
  );
}

export default PublicResultsGender;