import React, { useState, useEffect, useContext } from 'react';
import {useParams} from 'react-router-dom';
import useUserCode from './useUserCode';

// Components
import Summary from "../Summary";
import descriptions from "../adjectivesDescriptions";
import shuffle from "../shuffle";

// Services
import apiRequest from '../../services/apiRequest';
import { NotificationsContext } from '../../ui-components/Notifications';
import ApiService from "../../services/ApiService";

const Results = () => {
  const {session_uuid} = useParams();
  const {user_uuid} = useParams();
  const userCode = useUserCode(user_uuid);

  const [results, setResults] = useState();
  const [slides, setSlides] = useState();
  const [images, setImages] = useState();

  const {push} = useContext(NotificationsContext);

  useEffect(() => {
    const fetchParams = async () => {
      if (!images || !slides) {
        const [adj, img] = await Promise.all([
          ApiService.getApi("adjectives", "?private=true"),
          ApiService.getApi("images/all"),
        ]);

        if (!adj.length || !img.length) {
          throw new Error("missing data");
        }

        setImages(img);

        // copied from slides.js
        const tutorialAdjectives = adj.filter((a) => a.tutorial);
        const surveyAdjectives = adj.filter((a) => !a.tutorial).sort((a, b) => a.id - b.id);

        const slides = [
          ...tutorialAdjectives
            .filter((s, i) => i === 0)
            .map((a) => [
              {
                start: true,
                title: `Proviamo insieme`,
                subtitle: "ROUND PROVA",
                description: a.adjective,
                tutorial: true,
                adjectiveDescription: descriptions[a.adjective.slice(0, -1)],
              },
              {
                id_adjective: a.id,
                adjective: a.adjective,
                tutorial: true,
                images: shuffle(img.filter((img) => img.tutorial)),
              },
            ])
            .reduce((acc, a) => [...acc, ...a], []),
          ...surveyAdjectives
            .map((a, i) => [
              {
                start: true,
                title: `Round ${i + 1}`,
                description: a.adjective,
                adjectiveDescription: descriptions[a.adjective.slice(0, -1)],
              },
              {
                id_adjective: a.id,
                adjective: a.adjective,
                images: shuffle(img.filter((img) => !img.tutorial)),
              },
            ])
            .reduce((acc, a) => [...acc, ...a], [])
        ]
        
        setSlides(slides);
      }
    }

    fetchParams();
  }, [images, slides])

  useEffect(() => {
    const fetchResults = async () => {
      if (!results) {
        try {
          const responses = await apiRequest.get(`/responses/${session_uuid}/${user_uuid}`, {
            publicRequest: true,
          });

          if (responses) {
            setResults(responses);
          }
        } catch {
          push({
            type: "error",
            title: "generic error"
          })
        }
      }
    }

    fetchResults();
  }, [results]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {(results && slides && images) &&
        <div className="flex flex-col items-center">
          <h2
            id="summary-title"
            className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl mb-2 mt-10"
          >
            Riassunto
          </h2>
          <h3 className='mt-5 mb-5 text-xl font-bold'>Codice Utente: {userCode}</h3>
          <Summary result={results} slides={slides} images={images} />
          <div id="credits" className="mt-8 mb-24 flex-col items-center">
            <h2
              id="code-title"
              className="mt-2 mb-2 text-2xl text-center font-reg text-gray-900 tracking-tight sm:text-3xl"
            >
              Il dataset è stato realizzato come segue:
            </h2>
            <h2
              id="code"
              className="mt-4 mb-4 text-2xl text-center font-extrabold text-gray-900 tracking-tight sm:text-3xl"
            >
              13 volti generati artificialmente con{" "}
              <a href="https://generated.photos/" target="_blank" rel="noreferrer">
                Generated Photos
              </a>
            </h2>
            <h2
              id="code"
              className="text-2xl text-center font-extrabold text-gray-900 tracking-tight sm:text-3xl"
            >
              27 volti reali selezionati da{" "}
              <a href="https://www.shutterstock.com/it/" target="_blank" rel="noreferrer">
                Shutterstock
              </a>{" "}
              ,{" "}
              <a
                href="https://www.kaggle.com/ciplab/real-and-fake-face-detection"
                target="_blank"
                rel="noreferrer"
              >
                Kaggle Challenge
              </a>{" "}
              e{" "}
              <a
                href="https://figshare.com/articles/dataset/Face_Research_Lab_London_Set/5047666"
                target="_blank"
                rel="noreferrer"
              >
                London Faces
              </a>
            </h2>
          </div>
        </div>
      }
    </div>
  );
};

export default Results;