import Tooltip from "./Tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Tabs({ tabs, active, setActiveTab, tooltip = false }) {
  const _renderTab = (name, onClick, index) => {
    return (
      <div
        onClick={() => {
          setActiveTab(index);
          if (onClick) {
            onClick();
          }
        }}
        key={name}
        className={classNames(
          index === active
            ? "border-am-500 text-am-600"
            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
        )}
        aria-current={index === active ? "page" : undefined}
      >
        {name}
      </div>
    )
  }

  return (
    <div>
      <div className="block">
        <div className="border-b border-gray-200">
          <div className="-mb-px flex space-x-8" aria-label="Tabs">
            {/* grid to insert all adjectives in mobile mode. FIXME: find a way to get this grid dynamically */}
            <div className={tabs.length > 4 ? "grid grid-cols-3 sm:grid-cols-5" : "flex gap-x-4"}>
              {tabs.map(({ name, onClick, tooltipText }, index) => (
                <div key={name} className={tabs.length > 4 ? "mx-auto" : ""}>
                  {
                    tooltip ?
                      <Tooltip text={tooltipText}>
                        {_renderTab(name, onClick, index)}
                      </Tooltip> 
                      :
                      <>{_renderTab(name, onClick, index)}</>
                  }
                </div>
              ))}
          </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
