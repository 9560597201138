import React from "react";
import Spinner from "./Spinner";
import Icon from "./Icon";
import { TypesIconButton } from "./ui-components.types";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white hover:bg-gray-50",
  secondary: "text-am-700 bg-indigo-100 hover:bg-indigo-200",
  danger: "text-white bg-red-600 hover:bg-red-700",
};

const IconButton = ({
  full = "",
  className = "",
  styleType = "default",
  submitting = false,
  disabled = false,
  iconName = "",
  label = "",
  onClick = () => {},
  customStyle = {},
  ...rest
} : TypesIconButton) => {
  let defaultClasses =
    `relative flex-wrap content-center uppercase border border-transparent font-medium rounded-full shadow-sm focus:outline-none 
    ${disabled ? 'cursor-default opacity-30 focus:opacity-30 hover:opacity-30' : ' active:opacity-70'} `;
  // TODO: disable hover styling when button is disabled

  if ((buttonStyles as any)[styleType]) {
    defaultClasses += (buttonStyles as any)[styleType];
  } else {
    defaultClasses += buttonStyles.default;
  }

  return (
    <button
      disabled={submitting}
      style={customStyle}
      onClick={() => disabled ? undefined : onClick()}
      className={`${defaultClasses} ${className} ${full ? "w-full" : ""}`}
      {...rest}
    >
      <div className="flex flex-col items-center">
        <Icon iconName={iconName}/>
        <h4 className="text-tiny text-center">{label}</h4>
      </div>
      {submitting ? (
        <Spinner classes={(buttonStyles as any)[styleType]} styleType={styleType} iconButton={true}/>
      ) : null}
    </button>
  );
};

export default IconButton;
